<template>
  <div class="card card--table">
    <div
      v-b-toggle="`collapse-${tableId}`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">{{ title }}</a> <a><span
          class="badge bg-light-danger"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Pendings to sign documents"
        >{{ total }}</span></a>
      </h3>

      <div class="heading-elements">

        <ul class="list-inline mb-0">
          <li>
            <a
              data-action="collapse"
            ><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapse-${tableId}`"
      :class="{show: !show}"
      :style="{ display: !show ? 'block' : 'none' }"
      :visible="!show"
    >
      <div class="table-responsive-sm">
        <div
          id="actions-selected-table-01"
          class="actions__selected d-flex justify-content-between"
        > <!-- se muestra cuando se selecciona un checkbox de la tabla asociada -->
          <div>
            <p><strong>{{ selectedItems.length }}</strong> items selected</p></div>
          <div class="btn-group">
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0"
              right
            >
              <template #button-content>
                <button
                  id="dropdownMenuButton600"
                  class="btn btn-flat-dark dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Actions
                </button>
              </template>
              <div
                class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
              >
                <a
                  class="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#modalDeleteWithInput"
                  @click="deleteSelecteds"
                >Delete</a>
                <a
                  class="dropdown-item"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasAssignedWorkplace"
                  aria-controls="offcanvasAssignedWorkplace"
                  @click="assignData('workplace')"
                >Assigned Workplace</a>
                <a
                  class="dropdown-item"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasAssignedHazard"
                  aria-controls="offcanvasAssignedHazard"
                  @click="assignData('hazard')"
                >Assigned Hazard</a>
                <a
                  class="dropdown-item"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasMoveDocs"
                  aria-controls="offcanvasMoveDocs"
                  @click="moveDocuments"
                >Move documents </a>
                <a
                  class="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#modalConfirmArchived"
                  @click="archive"
                >Archive</a>
                <a
                  class="dropdown-item"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasSendNotification"
                  aria-controls="offcanvasSendNotification"
                  @click="sendNotifications"
                >Send notification</a>
              </div>
            </b-dropdown>
          </div>
        </div>
        <SortingTable
          :sorted-field="sortedField"
          :data="data"
          :sort-mapping="sortMapping"
          :can-select="true"
          :paginator-loading="loading"
          :fetch-path="fetchPath"
          :grouping-table="groupingTable"
          :allow-sort="true"
          @selectAll="selectAll"
          @selectItem="selectItem"
          @togglePaginator="setLoading"
        >
          <template v-slot="{field}">
            <td
              class="text-end"
            >
              <b-dropdown
                variant="link"
                no-caret
                toggle-class="p-0"
                right
              >
                <template #button-content>
                  <i data-feather="more-vertical" />
                </template>
                <div
                  class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                >
                  <b-dropdown-item
                    :href="$router.resolve({name: 'admin.labour-hazards.edit', params: { id: field.hazard.id }, query: { actAs: $route.query.actAs } }).href"
                    target="_blank"
                  >
                    <i
                      class="me-50"
                      data-feather="edit-3"
                    /> Edit
                  </b-dropdown-item>
                  <b-dropdown-item
                    target="_blank"
                    :href="field.url"
                  >
                    <i
                      class="me-50"
                      data-feather="eye"
                    /> View file
                  </b-dropdown-item>
                  <!-- <div class="dropdown-divider" />
                  <b-dropdown-item @click="$router.push({name: 'researcher.dashboard', params: { id: field.user.id }})">
                    <i
                      class="me-50"
                      data-feather="user"
                    /> View researcher profile
                  </b-dropdown-item>
                  <b-dropdown-item @click="showJobs(field)">
                    <i
                      class="me-50"
                      data-feather="pocket"
                    /> View workplaces & hazards
                  </b-dropdown-item> -->
                </div>
              </b-dropdown>
              <a
                class="btn btn-icon btn-light-secondary d-block d-sm-none"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasMenu"
                aria-controls="offcanvasBottom"
              ><i
                data-feather="more-vertical"
              /></a>
            </td>
          </template>
        </SortingTable>
      </div>
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-between">
          <Paginator
            :total="total"
            :store="fetchPath"
            @loading="setLoading"
          />
          <div>
            <select
              v-model="perPage"
              class="form-select"
              @change="setPerPage"
            >
              <option value="15">
                15 items per page
              </option>
              <option value="50">
                50 items per page
              </option>
              <option value="75">
                75 items per page
              </option>
              <option value="10000">
                All items per page
              </option>
            </select>
          </div>
        </div>
      </div>
    </b-collapse>
    <ItemInfoSheet :table-id="tableId">
      <template #info-sheet-title>
        <template v-if="selectedAction == 'jobs'"> <!-- /* SHOWING JOBS */ -->
          Researcher: workplaces & hazards
        </template>
        <template v-if="selectedAction == 'workplace' || selectedAction == 'hazard'"> <!-- /* ATTACHING WORKPLACE / HAZARD */ -->
          Assigned {{ selectedAction }}
        </template>
        <template v-if="selectedAction == 'documents'"> <!-- /* MOVE DOCUMENTS */ -->
          Move documents
        </template>
      </template>
      <template #info-sheet>
        <template v-if="selectedAction == 'jobs'"> <!-- /* SHOWING JOBS */ -->
          <div class="offcanvas-body offcanvas-body--view">
            <div class="mb-1 offcanvas-body--view--title">
              <p>Researcher<br>
                <strong>{{ item.hazard ? item.hazard.user.name : '' }}</strong></p>
            </div>
            <div class="mb-1 offcanvas-body--view--title">
              <p>Host<br>
                <strong>{{ item.hosts ? item.hosts.map(el => el.name).join(', ') : '' }}</strong></p>
            </div>
            <hr>
            <h4>Workplaces</h4>
            <div
              v-for="workplace, index in item.workplaces"
              :key="`w-${workplace.id}-${index}`"
              class="mb-1"
            >
              <div class="row">
                <div class="col-10">
                  <v-select
                    v-model="item.workplaces[index]"
                    :value="workplace"
                    label="name"
                    :clearable="false"
                    :options="workplaces"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'workplaces/filter')"
                  />
                </div>
                <div class="col-2">
                  <div
                    class="ms-1"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="delete job"
                    @click="deletePosition('workplaces', index)"
                  ><!-- dis-ib -->
                    <a class="btn btn-icon btn-icon rounded-circle btn-flat-danger"><i data-feather="trash-2" /></a>
                  </div>
                </div>
              </div>
            </div>
            <a
              class="btn btn-sm btn-dark"
              @click="addPosition('workplaces')"
            >Add workplace</a>

            <hr>
            <h4>Hazards</h4>
            <div
              v-for="hazard, index in item.hazards"
              :key="`h-${hazard.id}-${index}`"
              class="mb-1"
            >
              <div class="row">
                <div class="col-10">
                  <v-select
                    v-model="item.hazards[index]"
                    :value="hazard"
                    label="name"
                    :clearable="false"
                    :options="hazards"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'hazards/filter')"
                  />
                </div>
                <div class="col-2">
                  <a
                    class="mr-2 btn btn-icon btn-icon rounded-circle btn-flat-danger"
                    @click="deletePosition('hazards', index)"
                  ><i data-feather="trash-2" /></a>
                </div>
              </div>
            </div>
            <a
              class="btn btn-sm btn-dark"
              @click="addPosition('hazards')"
            >Add hazard</a>

          </div>
          <div class="offcanvas-footer mt-auto">
            <button
              type="button"
              class="btn btn-success mb-1 d-grid w-100"
              @click="saveItem"
            >
              Apply changes
            </button>
          </div>
        </template>
        <template v-if="selectedAction == 'workplace' || selectedAction == 'hazard'"> <!-- /* ATTACHING WORKPLACE / HAZARD */ -->
          <div class="offcanvas-body offcanvas-body--view">
            <div class="mb-1">
              <label
                class="form-label text-capitalize"
              >{{ selectedAction }}</label>
              <v-select
                v-if="selectedAction == 'workplace'"
                v-model="selectedWorkplaces"
                label="name"
                multiple
                :clearable="false"
                :options="workplaces"
                :get-option-key="option => option.id"
                @search="onSearch({ name: $event }, 'workplaces/filter')"
              />
              <v-select
                v-if="selectedAction == 'hazard'"
                v-model="selectedHazards"
                label="name"
                multiple
                :clearable="false"
                :options="hazards"
                :get-option-key="option => option.id"
                @search="onSearch({ name: $event }, 'hazards/filter')"
              />
            </div>
          </div>
          <div class="offcanvas-footer mt-auto">
            <button
              type="button"
              class="btn btn-success mb-1 d-grid w-100"
              @click="attachValues"
            >
              Apply workplace
            </button>
          </div>
        </template>
        <template v-if="selectedAction == 'documents'"> <!-- /* MOVE DOCUMENTS */ -->
          <div class="offcanvas-body offcanvas-body--view">
            <div class="mb-1 offcanvas-body--view--title">
              <p><strong>To:</strong><br></p>
            </div>
            <hr>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Section</label>
              <v-select
                v-model="selectedSection"
                label="name"
                :clearable="false"
                :options="sections"
                :get-option-key="option => option.id"
                @search="onSearch({ name: $event, type: 'parent' }, 'safeties/filter')"
                @input="loadChildrens($event)"
              />
            </div>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Subsection</label>
              <v-select
                v-model="selectedSubsection"
                label="name"
                :clearable="false"
                :options="subsections"
                :get-option-key="option => option.id"
              />
            </div>
          </div>
          <div class="offcanvas-footer mt-auto">
            <button
              type="button"
              class="btn btn-success mb-1 d-grid w-100"
              @click="moveFiles"
            >
              Apply changes
            </button>
          </div>
        </template>
      </template>
    </ItemInfoSheet>
    <SendNotification
      :table-id="tableId"
      :users-selected="selectedUsers"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { BDropdown, BDropdownItem, BCollapse } from 'bootstrap-vue'
import Paginator from '../Paginator.vue'
import SortingTable from './SortingTable.vue'
import ItemInfoSheet from '../offcanvas/ItemInfoSheet.vue'
import SendNotification from '../offcanvas/SendNotification.vue'

export default {
  components: {
    Paginator,
    SortingTable,
    BDropdown,
    BDropdownItem,
    BCollapse,
    ItemInfoSheet,
    vSelect,
    SendNotification,
  },
  props: {
    data: { type: Array, required: true },
    sortedField: { type: String, required: true },
    sortMapping: { type: Object, required: true },
    title: { type: String, required: true },
    total: { type: Number, required: true },
    fetchPath: { type: String, required: true },
    tableId: { type: Number, required: false, default: 0 },
    show: { type: Boolean, required: false, default: null },
    groupingTable: { type: Boolean, required: false, default: false },
    loading: { type: Boolean, required: true, default: false },
  },
  data() {
    return {
      selectedItems: [],
      item: {},
      selectedUsers: [],
      selectedAction: '',
      selectedHazards: [],
      selectedWorkplaces: [],
      selectedSection: null,
      selectedSubsection: null,
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      filters: 'filters/filters',
      workplaces: 'workplaces/workplaces',
      hazards: 'hazards/hazards',
      visible: 'modals/infoSheet',
      visible_notification: 'modals/sendNotification',
      sections: 'safeties/safeties',
      subsections: 'safeties/childrens',
      groupedBy: 'labourHazards/groupedBy',
      tableClicked: 'modals/tableClicked',
      perPage: 'pagination/perPage',
    }),
  },
  watch: {
    async visible() {
      if (!this.visible) {
        this.$store.dispatch('modals/toggleTableClicked', null)
        await this.$store.dispatch('labourHazards/fetchFiles')
      }
    },
    async visible_notification() {
      if (!this.visible_notification) {
        this.$store.dispatch('modals/toggleTableClicked', null)
      }
    },
  },
  methods: {
    setLoading(loading) {
      this.$emit('toggleLoading', loading)
    },
    selectAll(items = null) {
      if (items) {
        this.selectedItem = items
      } else if (items === false) {
        this.selectedItems = []
      } else {
        this.selectedItems = [...this.data]
      }
    },
    selectItem(data) {
      if (data.click) {
        this.selectedItems.push(data.item)
        if (this.selectedUsers.findIndex(x => x.id === data.item.hazard.user.id) < 0) {
          this.selectedUsers.push(data.item.hazard.user)
        }
      } else {
        this.selectedItems.splice(this.selectedItems.findIndex(x => x.id === data.item.id), 1)
        this.selectedUsers.splice(this.selectedUsers.findIndex(x => x.id === data.item.hazard.user.id), 1)
      }
    },
    showJobs(item) {
      this.item = item
      this.selectedAction = 'jobs'
      this.$store.dispatch('modals/toggleTableClicked', this.tableId)
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    async saveItem() {
      await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users/save-jobs`, {
        workplaces: this.item.workplaces,
        hazards: this.item.hazards,
      })

      this.$store.dispatch('modals/toggleInfoItemSheet', false)
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    addPosition(position) {
      this.item[position].push({ name: 'Select an option', id: 0 })
    },
    deletePosition(position, index) {
      this.item[position].splice(index, 1)
    },
    async loadChildrens(data) {
      await this.$store.dispatch('safeties/getChildrens', data.id)
    },
    async setPerPage(perPage) {
      this.selectedItems = []
      this.setLoading(true)
      this.$store.dispatch('pagination/clearPage')
      await this.$store.dispatch('pagination/savePerPage', perPage.target.value)
      if (this.fetchPath) {
        await this.$store.dispatch(this.fetchPath, this.filters)
      }
      this.setLoading(false)
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    async deleteSelecteds() {
      Vue.swal({
        title: 'Delete the selected items?',
        text: "Are you sure? You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete!',
      }).then(result => {
        if (result.isConfirmed && this.selectedItems.length > 0) {
          const itemIds = this.selectedItems.map(el => el.id)
          this.$store.dispatch('labourHazards/deleteFiles', itemIds).then(() => {
            this.$toastr.success('', 'Files deleted successfully!')
            if (this.groupedBy) {
              this.$store.dispatch('labourHazards/fetchGroupedData', this.groupedBy)
            } else {
              this.$store.dispatch('labourHazards/fetchFiles')
            }
          })
        } else if (this.selectedItems.length === 0) {
          this.$toastr.error('', 'No items selected!')
        }
      })
    },
    async archive() {
      Vue.swal({
        title: 'Archive the selected items?',
        text: "Are you sure? You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes, archive!',
      }).then(result => {
        if (result.isConfirmed && this.selectedItems.length > 0) {
          const itemIds = this.selectedItems.map(el => el.id)
          this.$store.dispatch('labourHazards/archiveFiles', itemIds).then(() => {
            this.$toastr.success('', 'Files archived successfully!')
            if (this.groupedBy) {
              this.$store.dispatch('labourHazards/fetchGroupedData', this.groupedBy)
            } else {
              this.$store.dispatch('labourHazards/fetchFiles')
            }
          })
        } else if (this.selectedItems.length === 0) {
          this.$toastr.error('', 'No items selected!')
        }
      })
    },
    assignData(name) {
      this.selectedAction = name
      this.$store.dispatch('modals/toggleTableClicked', this.tableId)
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    moveDocuments() {
      this.selectedAction = 'documents'
      this.$store.dispatch('modals/toggleTableClicked', this.tableId)
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    moveFiles() {
      if (this.selectedItems.length > 0) {
        const itemIds = this.selectedItems.map(el => el.hazard.id)
        this.$store.dispatch('labourHazards/moveDocuments', { ids: itemIds, section: this.selectedSection, subsection: this.selectedSubsection }).then(() => {
          this.$toastr.success('', 'Documents moved successfully')
          if (this.groupedBy) {
            this.$store.dispatch('labourHazards/fetchGroupedData', this.groupedBy)
          } else {
            this.$store.dispatch('labourHazards/fetchFiles')
          }
          this.$store.dispatch('modals/toggleInfoItemSheet', false)
        })
      } else {
        this.$toastr.error('', 'No items selected!')
      }
    },
    attachValues() {
      if (this.selectedItems.length > 0) {
        let data = null

        if (this.selectedAction === 'workplace') {
          data = this.selectedWorkplaces.map(el => el.id)
        } else if (this.selectedAction === 'hazard') {
          data = this.selectedHazards.map(el => el.id)
        }

        const itemIds = this.selectedItems.map(el => el.hazard.user.id)
        this.$store.dispatch('labourHazards/assign', { ids: itemIds, data, type: this.selectedAction }).then(() => {
          this.$toastr.success('', `${this.selectedAction} assigned successfully`)
          if (this.groupedBy) {
            this.$store.dispatch('labourHazards/fetchGroupedData', this.groupedBy)
          } else {
            this.$store.dispatch('labourHazards/fetchFiles')
          }
          this.$store.dispatch('modals/toggleInfoItemSheet', false)
        })
      } else {
        this.$toastr.error('', 'No items selected!')
      }
    },
    sendNotifications() {
      this.$store.dispatch('modals/toggleTableClicked', this.tableId)
      this.$store.dispatch('modals/toggleSendNotification', true)
    },
  },
}
</script>
