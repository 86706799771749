import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    groups: {},
    section: {},
    requiredFields: 0,
    filledRequiredFields: 0,
    payload: null,
  },
  getters: {
    groups: state => state.groups,
    section: state => state.section,
    requiredFields: state => state.requiredFields,
    filledRequiredFields: state => state.filledRequiredFields,
    percentage: state => ((100 / state.requiredFields) * state.filledRequiredFields).toFixed(0),
    payload: state => state.payload,
  },
  mutations: {
    SAVE_GROUPS(state, groups) {
      state.groups = groups
    },
    SAVE_SECTION(state, section) {
      state.section = section
    },
    SAVE_REQUIRED_FIELDS(state, requiredFields) {
      state.requiredFields += requiredFields[0]
      state.filledRequiredFields += requiredFields[1]
    },
    SAVE_SELECTED_URL(state, payload) {
      state.payload = payload
    },
    CLEAR(state) {
      state.groups = {}
      state.requiredFields = 0
      state.filledRequiredFields = 0
    },
  },
  actions: {
    async fetch({ commit }, sectionId) {
      try {
        commit('CLEAR')

        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo-section/${sectionId}`)
        if (resp.status === 200) {
          commit('SAVE_SECTION', resp.data.data)
          commit('SAVE_REQUIRED_FIELDS', [0, 0])
        } else {
          commit('CLEAR')
        }
      } catch (e) {
        commit('CLEAR')
      }
    },
    async fetchGroupsByUser({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo-section/${data[0]}/groups/${data[1]}/${data[2] ?? ''}`)
        if (resp.status === 200) {
          commit('SAVE_GROUPS', resp.data.data)
        } else {
          commit('CLEAR')
        }
      } catch (e) {
        commit('CLEAR')
      }
    },
    async fetchGroups({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo-section/${data.sectionId}/groups-convo/${data.convoId}`)
        if (resp.status === 200) {
          commit('SAVE_GROUPS', resp.data.data)
        } else {
          commit('CLEAR')
        }
      } catch (e) {
        commit('CLEAR')
      }
    },
    async calculatePercentage({ commit }, [requiredFields, filledRequiredFields]) {
      commit('SAVE_REQUIRED_FIELDS', [requiredFields, filledRequiredFields])
    },
    async getInstitutionUrl({ commit }, payload) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/parameter/generate-institution-url`, { payload })
        if (resp.status === 200) {
          commit('SAVE_SELECTED_URL', resp.data)
        }

        return null
      } catch (e) {
        return null
      }
    },
  },
}
