<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                Add peers - {{ convo.title }}<br>
                <small v-if="userPcmember">ICREA: {{ userPcmember.name }}</small>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to promotion call"
              @click="!userPcmember ? $router.push({name: 'researcher.promotion-call.index'}) : $router.push({ name: 'evaluator.calls.evaluations.edit-peers', params: {
                userPcmember
              } })"
            ><i data-feather="chevron-left" /></a>
            <a
              v-if="user.id && !loading && ((loggedUser.roles.includes('researcher') && peers.filter(e => e.pivot && !e.pivot.blacklist).length < 3) || !loggedUser.roles.includes('researcher'))"
              class="btn btn-icon btn-primary ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Propose new peers"
              @click="$store.dispatch('modals/toggleProposeEvaluator', true)"
            ><i data-feather="plus" /> Propose new peer</a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Research lines settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div
        class="content-body content-body--senior-call"
      >
        <div class="row">
          <div class="col-12">

            <SearchResearcher
              :item="item"
              :only-promotion-researcher="convo.id"
            />

            <div class="row">
              <div class="mb-1 col-3">
                <label
                  for=""
                  class="form-label"
                >Name, institution or email</label>
                <input
                  v-model="filters.name_institution_mail"
                  type="text"
                  class="form-control"
                  name="nameseniorcall"
                  @change="saveFilter({ customLabel: true, name: `Name, institution or email: ${$event.target.value}`, value: `${$event.target.value}` }, 'name_institution_mail')"
                >
              </div>
              <div
                v-if="!loggedUser.roles.includes('researcher')"
                class="mb-1 col-2"
              >
                <label
                  for="nameseniorcall"
                  class="form-label"
                >ERC</label>
                <v-select
                  v-model="filters.erc"
                  label="name"
                  :options="ercs"
                  :get-option-key="option => option.id"
                  @input="saveFilter({ customLabel: true, name: `Erc: ${$event ? $event.name : ''}`, value: $event }, 'erc')"
                  @search="onSearch({ name: $event }, 'erc/filter')"
                />
              </div>
              <div
                v-if="!loggedUser.roles.includes('researcher')"
                class="mb-1 col-3"
              >
                <label
                  for=""
                  class="form-label"
                >Keywords</label>
                <input
                  v-model="filters.keywords"
                  type="text"
                  class="form-control"
                  name="nameseniorcall"
                  @change="saveFilter({ customLabel: true, name: `Keywords: ${$event.target.value}`, value: `${$event.target.value}` }, 'keywords')"
                >
              </div>
              <div
                class="mb-1 col-2"
              >
                <label
                  for="nameseniorcall"
                  class="form-label"
                >Area</label>
                <v-select
                  v-model="filters.area"
                  label="name"
                  :options="areas"
                  :get-option-key="option => option.id"
                  @input="saveFilter({ customLabel: true, name: `Area: ${$event ? $event.name : ''}`, value: $event ? $event.name : null }, 'area')"
                  @search="onSearch({ name: $event }, 'areas/filter')"
                />
              </div>
              <div class="col-2 mt-1">
                <button
                  type="button"
                  class="btn btn-dark mb-1 d-grid w-100"
                  @click="filter"
                >
                  Apply
                </button>
              </div>
            </div>
            <div
              v-if="selectedItems.length > 0"
              class="alert alert-warning"
            >
              <div class="alert-body">
                <div class="row align-items-center">
                  <div class="col-auto text-dark">
                    <strong>{{ selectedItems.length }}</strong> peers selected:
                  </div>
                  <template v-for="user, index in selectedItems">
                    {{ index != 0 && index < 4 ? ' - ' : '' }} {{ index < 4 ? user.name : '' }}  {{ index == 4 ? ' and more...' : '' }}
                  </template>
                  <div class="col-3 text-dark">
                    <v-select
                      v-model="actions.assign"
                      label="name"
                      :options="peerOptions"
                      :reduce="e => e.value"
                      :get-option-key="option => option.value"
                    />
                  </div>
                  <div class="col-auto">
                    <button
                      class="btn btn-dark"
                      @click="applyActions"
                    >
                      <template v-if="!loading">
                        Apply
                      </template>
                      <div
                        v-else
                        class="loading custom-loading"
                      >
                        <div class="effect-1 effects" />
                        <div class="effect-2 effects" />
                        <div class="effect-3 effects" />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <CompleteTable
              v-if="!loading && user.id"
              :sorted-field="sortedField"
              :data="peersTable"
              :sort-mapping="sortMapping"
              :title="title"
              :total="evaluatorsTotal"
              :fetch-path="'users/fetchEvaluators'"
              :filter-path="'users/filterEvaluators'"
              :actions="true"
              :show-actions="false"
              :show-actions-column="!loggedUser.roles.includes('pc-member')"
              @selectedItems="saveSelectedItems"
            >
              <template v-slot="{field}">
                <td
                  v-if="!loggedUser.roles.includes('pc-member')"
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item
                        :href="$router.resolve({name: 'admin.users.edit', params: { id: field.id }, query: { actAs: $route.query.actAs } }).href"
                        target="_blank"
                      >
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> Go to profile
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </td>
              </template>
              <template #customfilters>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Name, institution or email</label>
                  <input
                    v-model="filters.name_institution_mail"
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter({ customLabel: true, name: `Name, institution or email: ${$event.target.value}`, value: $event.target.value }, 'name_institution_mail')"
                  >
                </div>
                <div
                  class="mb-1"
                >
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Erc</label>
                  <v-select
                    v-model="filters.erc"
                    label="name"
                    :options="ercs"
                    :get-option-key="option => option.id"
                    @input="saveFilter({ customLabel: true, name: `Erc: ${$event ? $event.name : ''}`, value: $event }, 'erc')"
                    @search="onSearch({ name: $event }, 'erc/filter')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Keywords</label>
                  <input
                    v-model="filters.keywords"
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter({ customLabel: true, name: `Keywords: ${$event.target.value}`, value: $event.target.value}, 'keywords')"
                  >
                </div>
                <div
                  class="mb-1"
                >
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Area</label>
                  <v-select
                    v-model="filters.area"
                    label="name"
                    :options="areas"
                    :get-option-key="option => option.id"
                    @input="saveFilter($event.name, 'area')"
                  />
                </div>
              </template>
              <template #info-sheet-item>
                <div class="offcanvas-body offcanvas-body--view">

                  <h4 class="mb-2">
                    <strong class="text-success">{{ selectedItems.length }}</strong> selected peers
                  </h4>

                  <ul
                    id="basic-list-group"
                    class="list-group"
                  >
                    <li class="list-group-item draggable">
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              id="tableAction1"
                              v-model="actions.assign"
                              type="radio"
                              class="form-check-input"
                              name="actions"
                              value="1"
                            >
                            <label
                              class="form-check-label"
                              for="tableAction1"
                            >Add a peer</label>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item draggable">
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              id="tableAction1"
                              v-model="actions.assign"
                              type="radio"
                              class="form-check-input"
                              name="actions"
                              value="2"
                            >
                            <label
                              class="form-check-label"
                              for="tableAction1"
                            >Remove a peer</label>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item draggable">
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              id="tableAction1"
                              v-model="actions.assign"
                              type="radio"
                              class="form-check-input"
                              name="actions"
                              value="3"
                            >
                            <label
                              class="form-check-label"
                              for="tableAction1"
                            >Send to blacklist</label>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item draggable">
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              id="tableAction1"
                              v-model="actions.assign"
                              type="radio"
                              class="form-check-input"
                              name="actions"
                              value="4"
                            >
                            <label
                              class="form-check-label"
                              for="tableAction1"
                            >Remove from blacklist</label>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="offcanvas-footer mt-auto">
                  <a
                    href="#"
                    title="apply actions"
                    class="btn btn-primary mb-1 d-grid w-100"
                    @click="applyActions"
                  >Apply actions</a>
                </div>
              </template>
            </CompleteTable>
            <div
              v-if="loading"
              class="loading custom-loading m-auto"
            >
              <div class="effect-1 effects" />
              <div class="effect-2 effects" />
              <div class="effect-3 effects" />
            </div>
          </div>
          <ProposeEvaluator />
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import SearchResearcher from '../../partials/components/SearchResearcher.vue'
import 'vue2-datepicker/index.css'
import CompleteTable from '../../partials/components/CompleteTable.vue'
import ProposeEvaluator from './components/ProposeEvaluator.vue'

export default {
  components: {
    vSelect,
    SearchResearcher,
    CompleteTable,
    BDropdown,
    BDropdownItem,
    ProposeEvaluator,
  },
  data() {
    return {
      item: {
        user: null,
      },
      loading: true,
      errorMsg: null,
      selectedItems: [],
      sortedField: 'Name',
      defaultFields: [
        {
          name: 'Evaluator',
          checked: true,
          order: 1,
        },
        {
          name: 'Area',
          checked: true,
          order: 2,
        },
        {
          name: 'Keywords',
          checked: true,
          order: 3,
        },
        {
          name: 'Institution',
          checked: true,
          order: 4,
        },
        {
          name: 'Erc',
          checked: true,
          order: 5,
        },
        {
          name: 'Status',
          checked: true,
          order: 6,
        },
      ],
      sortMapping: {
        Evaluator: 'name',
        Area: 'areas',
        Keywords: 'keywords_table',
        Institution: 'hosts_table',
        Erc: 'erc.code',
        Status: 'status',
      },
      actions: {
      },
      userPcmember: this.$route.params.userPcmember ?? null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/user',
      loggedUser: 'auth/admin',
      evaluators: 'users/evaluators',
      evaluatorsTotal: 'users/evaluatorsTotal',
      peers: 'users/peers',
      areas: 'areas/areas',
      convo: 'convo/convo',
      ercs: 'erc/items',
      filters: 'filters/filters',
    }),
    peerOptions() {
      const options = [
        { name: 'Add peer/s', value: 1 },
        { name: 'Remove peer/s', value: 2 },
      ]

      if (!this.userPcmember) {
        options.push({ name: 'Send peer/s to blacklist', value: 3 })
        options.push({ name: 'Remove peer/s from the blacklist', value: 4 })
      }

      return options
    },
    peersTable() {
      const aux = []
      this.evaluators.forEach(e => {
        let canBeAdded = true
        if (this.peers.filter(f => f.id === e.id).length === 1) {
          const peer = this.peers.filter(f => f.id === e.id)[0]
          e.status = this.peers.filter(f => f.id === e.id)[0].status

          if (this.userPcmember && peer.status_id === 999) {
            canBeAdded = false
          }
        }

        if (canBeAdded) {
          aux.push(e)
        }
      })

      return aux
    },
    realUser() {
      return this.item.user
    },
    title() {
      if (this.userPcmember && this.userPcmember.id === this.user.id) {
        return `peers - <span class="badge bg-light-success">${this.peers.filter(e => e.pivot && !e.pivot.blacklist).length} peers</span>`
      }

      if (this.peers.filter(e => e.pivot && !e.pivot.blacklist).length === 0) {
        return `peers - <span class="badge bg-light-danger">You have added ${this.peers.filter(e => e.pivot && !e.pivot.blacklist).length} of 3 peers</span>`
      }

      if (this.peers.filter(e => e.pivot && !e.pivot.blacklist).length === 3) {
        return `peers - <span class="badge bg-light-success">You have added ${this.peers.filter(e => e.pivot && !e.pivot.blacklist).length} of 3 peers</span>`
      }

      return `peers - <span class="badge bg-light-warning">You have added ${this.peers.filter(e => e.pivot && !e.pivot.blacklist).length} of 3 peers</span>`
    },
  },
  watch: {
    async realUser() {
      this.loading = true
      this.$store.dispatch('users/setUserManagement', this.item.user.id)
      this.$store.dispatch('users/filterEvaluators')
      this.$store.dispatch('users/getPeers', this.item.user.id)
      await this.$store.dispatch('users/fetchUser', this.item.user.id)
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
      this.loading = false
    },
  },
  async mounted() {
    this.$store.dispatch('users/cleanPeers')
    this.$store.dispatch('users/cleanUser')
    this.$store.dispatch('modals/fetchUserFields', 'add_peers')
    this.$store.dispatch('areas/filter')

    if (this.userPcmember) {
      this.$store.dispatch('users/setUserManagement', this.userPcmember.id)
      this.$store.dispatch('users/filterEvaluators')
      this.$store.dispatch('users/getPeers', this.userPcmember.id)
      await this.$store.dispatch('users/fetchUser', this.userPcmember.id)
    } else if (this.loggedUser.roles.includes('pc-member')) {
      this.$router.push({ name: 'evaluator.calls.evaluations.profile' })
    }

    // Check if there is some promotion going on
    await this.$store.dispatch('convo/getActivePromotion')
    if (!this.convo || this.convo.days === 0) {
      this.$router.push({ name: 'researcher.promotion-call.index' })
    }

    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'add_peers',
      })
    }

    if (!this.loggedUser.roles.includes('super-admin') && !this.loggedUser.roles.includes('pc-member')) {
      this.$store.dispatch('users/setUserManagement', this.loggedUser.id)
      this.$store.dispatch('users/filterEvaluators')
      this.$store.dispatch('users/getPeers', this.loggedUser.id)
      await this.$store.dispatch('users/fetchUser', this.loggedUser.id)
    }

    this.loading = false
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 100)
  },
  methods: {
    async applyActions() {
      if (this.selectedItems.length > 0 && this.actions.assign != null) {
        if (this.canApplyActions()) {
          Vue.swal({
            title: 'Perform the action on the selected peer/peers?',
            html: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Yes',
          }).then(async result => {
            this.loading = true
            if (result.isConfirmed) {
              const itemIds = this.selectedItems.map(el => el.id)
              if ((this.userPcmember && this.userPcmember.id !== this.user.id) || (!this.userPcmember && this.peers.filter(e => !e.pivot.blacklist).length + itemIds.length > 3 && this.actions.assign == 1)) {
                Vue.swal('You can\'t have/select more than 3 peers', '', 'error')
              } else {
                await this.$store.dispatch('users/setPeers', { actions: this.actions, itemIds, researcherId: this.user.id })
                this.saveSelectedItems([])
                this.actions = {}
                this.$store.dispatch('users/filterEvaluators')
                Vue.swal('Actions applied successfully', '', 'success')
                this.$store.dispatch('modals/toggleInfoItemSheet', false)
              }
            }
            this.loading = false
          })
        } else {
          Vue.swal(this.errorMsg, '', 'error')
        }
      } else {
        this.$toastr.error('', 'No actions selected')
      }
    },
    async onSearch(search, store) {
      const s = search
      s.active = true
      if (s.name !== '') {
        await this.$store.dispatch(store, s)
      }
    },
    canApplyActions() {
      if (this.actions.assign === 1 || this.actions.assign === 3) {
        return true
      }

      if (this.actions.assign === 2) {
        this.errorMsg = 'You can\'t remove a non-assigned peer'
        return this.selectedItems.length === this.selectedItems.filter(e => e.status != null).length
      }

      if (this.actions.assign === 4) {
        this.errorMsg = 'You can\'t remove from blacklist a non-blacklisted peer'
        return this.selectedItems.length === this.selectedItems.filter(e => e.status && e.status.includes('Blacklisted')).length
      }

      return false
    },
    saveSelectedItems(items) {
      this.selectedItems = items
    },
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    async filter() {
      this.loading = true
      this.$store.dispatch('pagination/clearPage')
      await this.$store.dispatch('users/filterEvaluators', this.filters)
      this.loading = false
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
  },
}
</script>
