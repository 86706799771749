<template>
  <!-- BEGIN: Content-->
  <div class="card card--table">
    <div
      v-b-toggle="`collapsePeopleFunded`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">People Funded with the {{ grant.type.parent_type == 2 ? `Host support - ${grant.type.name}` : grant.type.name }}</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapsePeopleFunded`"
      visible
    >
      <div class="card-content collapse show">
        <div
          v-if="grant.type.type === 'regular'"
          class="card-body"
        > <!-- activar esta opción habilita el poder añadir personas -->
          <div class="row">
            <div class="col">
              <div class="form-check form-check-success form-switch ">
                <input
                  id=""
                  v-model="peopleHired"
                  type="checkbox"
                  class="form-check-input"
                >
                <label
                  class="form-check-label"
                  for=""
                ><h5 class="ms-1">Are there people hired/financed with this funding?</h5></label>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="grant.type.type == 'personnel'"
          class="card-body"
        >
          <div class="row">
            <div class="col">
              <div class="mb-1">
                <div class="form-check form-check-success form-switch d-flex align-items-center">
                  <input
                    v-model="grant.publish_in_web"
                    type="checkbox"
                    class="form-check-input"
                    checked
                  >
                  <label
                    class="form-check-label"
                    for=""
                  ><small class="ms-1">{{ labels['people_funded.active'] }} </small></label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <table
          v-if="peopleHired || (grant.type.type === 'personal' || grant.type.type === 'personnel')"
          class="table table-striped table-hover"
        >
          <thead>
            <tr>
              <th>Type of personnel</th>
              <th>Full name</th>
              <th>From</th>
              <th>To</th>
              <th>Institution</th>
              <th>Dedication</th>
              <th class="text-end">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="worker, index in grant.personnels"
              :key="worker.id"
            >
              <td>{{ worker.type ? worker.type.name : '-' }}</td>
              <td>{{ worker.name }}</td>
              <td>{{ worker.from }}</td>
              <td>{{ worker.to }}</td>
              <td>{{ worker.host ? worker.host.name : '-' }}</td>
              <td>{{ worker.dedication }}%</td>
              <td class="text-end">
                <span
                  class="dis-ib"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                >
                  <a
                    class="
                      btn btn-icon btn-icon
                      rounded-circle
                      btn-flat-dark
                      me-1
                    "
                    title="Edit"
                    @click="editWorker(index)"
                  ><i data-feather="edit" /></a>
                  <a
                    class="btn btn-icon rounded-circle btn-flat-danger"
                    title="Delete"
                    @click="deleteUser(index)"
                  ><i data-feather="trash-2" /></a>
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          v-if="peopleHired || (grant.type.type === 'personal' || grant.type.type === 'personnel')"
          class="card-body"
        >
          <div class="row">
            <div class="col">

              <div class="mt-2">
                <a
                  class="btn btn-sm btn-dark dis-ib"
                  @click="changeOpenEdit()"
                ><i data-feather="plus" /> Add people</a>

                <b-collapse
                  :id="'collapseFundedPeople'"
                  :visible="openEdit"
                  class="collapse mt-3"
                >
                  <div class="card shadow-none bg-transparent border-secondary">
                    <div class="card-body">
                      <h3>Add people</h3>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="mb-1">
                            <label
                              for="formFile"
                              class="form-label"
                            >{{ labels['people_funded.type'] }}</label>
                            <v-select
                              v-model="newPeople.type"
                              label="name"
                              :clearable="false"
                              :options="types"
                              :get-option-key="option => option.id"
                              @search="onSearch({ name: $event }, 'typesPersonnel/filter')"
                            />
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class="mb-1">
                            <label
                              for=""
                              class="form-label"
                            >{{ labels['people_funded.name'] }}</label>
                            <input
                              v-model="newPeople.name"
                              type="text"
                              class="form-control"
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-2">
                          <div class="mb-1">
                            <label
                              for=""
                              class="form-label"
                            >{{ labels['people_funded.from'] }}</label> <!-- controlar que no sea anterior a la fecha de inicio de la grant -->
                            <date-picker
                              v-model="newPeople.from"
                              format="D MMM Y"
                              value-type="format"
                              class="w-100"
                            />
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="mb-1">
                            <label
                              for=""
                              class="form-label"
                            >{{ labels['people_funded.to'] }}</label> <!-- puede ser posterior a la fecha de finalización de la grant -->
                            <date-picker
                              v-model="newPeople.to"
                              format="D MMM Y"
                              value-type="format"
                              class="w-100"
                            />
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="mb-1">
                            <label
                              for=""
                              class="form-label"
                            >{{ labels['people_funded.dedication'] }}</label>
                            <div class="input-group">
                              <input
                                v-model="newPeople.dedication"
                                type="number"
                                class="form-control"
                              >
                              <a
                                class="btn btn-outline-primary"
                              >%</a>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label
                            for=""
                            class="form-label w-100"
                          >{{ labels['people_funded.host_institution'] }}
                          </label>
                          <div
                            class="mb-1"
                          >
                            <v-select
                              v-model="newPeople.host"
                              label="name"
                              :options="userHosts"
                              :get-option-key="option => option.id"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="mb-2">
                        <a
                          class="btn btn-sm btn-outline-success me-1"
                          @click="saveNewUser"
                        >{{ editItem !== null ? 'Update' : 'Save' }} data</a>
                        <a
                          class="btn btn-sm btn-flat-danger"
                          @click="cancelEdit()"
                        >Cancel</a>
                      </div>
                      <div class="mb-2">
                        <span class="text-warning form-label"><i data-feather="alert-triangle" /> Make sure to save your changes to be able to save the grant</span>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>

        </div>
      </div>
    </b-collapse>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    BCollapse,
    vSelect,
    DatePicker,
  },
  data() {
    return {
      peopleHired: false,
      newPeople: {},
      openEdit: false,
      editItem: null,
      userHosts: [],
    }
  },
  computed: {
    ...mapGetters({
      grant: 'grants/item',
      types: 'typesPersonnel/items',
      loggedUser: 'auth/admin',
      labels: 'sectionLabels/itemLabels',
    }),
  },
  watch: {
    peopleHired() {
      if (this.peopleHired && (this.grant.type.type === 'regular' || this.grant.type.type === 'monetary')) {
        const check = this.checkForMissingAmounts()
        if (!check) {
          Vue.swal('Have you forget to distribute the amounts by years?', 'Please, kindly check and distribute it if necessary', 'warning')
        }
      }
    },
    'newPeople.from': function (newDate) {
      if (this.newPeople.to && newDate) {
        this.$emit('checkDates', [newDate, this.newPeople.to])
      }
    },
    'grant.user': function () {
      this.setUserHost(null)
      this.setHosts()
    },
    // 'newPeople.to': function (newDate) {
    //   if (this.newPeople.from && newDate) {
    //     this.$emit('checkDates', [this.newPeople.from, newDate])
    //   }
    // },
  },
  async mounted() {
    await this.$store.dispatch('typesPersonnel/fetch')
    this.peopleHired = this.grant.personnels.length > 0
    this.setHosts()
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    setHosts() {
      if (!this.loggedUser.roles.includes('super-admin')) {
        if (this.loggedUser.hosts && this.loggedUser.hosts.length === 1) {
          const host = this.loggedUser.hosts[0]
          this.setUserHost(host)
          this.userHosts = this.loggedUser.hosts
        } else {
          this.userHosts = this.loggedUser.hosts
        }
      } else if (this.grant.user.hosts && this.grant.user.hosts.length === 1) {
        const host = this.grant.user.hosts[0]
        this.setUserHost(host)
        this.userHosts = this.grant.user.hosts
      } else {
        this.userHosts = this.grant.user.hosts
      }
    },
    setUserHost(host) {
      this.newPeople.host = host
    },
    checkForMissingAmounts() {
      let canSave = true

      if (this.grant.type.type === 'regular') {
        const missingAmounts = this.grant.amounts.findIndex(x => x.amount === 'NaN' || !x.amount)

        if (missingAmounts >= 0) {
          canSave = false
        }
      }

      return canSave
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    saveNewUser() {
      if (this.grant.type.type !== 'personnel' && !this.compareDates(this.grant.begin_date, this.newPeople.from)) {
        this.$toastr.error('', 'Funding date before the grant start date!')
      } else {
        if (this.editItem !== null) {
          this.grant.personnels[this.editItem] = this.newPeople
        } else {
          this.grant.personnels.push(this.newPeople)
        }

        this.cancelEdit()

        setTimeout(() => {
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 150)
      }
    },
    deleteUser(index) {
      this.grant.personnels.splice(index, 1)
    },
    cancelEdit() {
      this.openEdit = false
      this.newPeople = {}
      this.editItem = null
      this.$emit('openEdit', this.openEdit)
    },
    editWorker(index) {
      this.openEdit = true
      this.$emit('openEdit', this.openEdit)
      const item = this.grant.personnels[index]

      this.editItem = index

      this.newPeople = {
        type: item.type,
        name: item.name,
        from: item.from,
        to: item.to,
        dedication: item.dedication,
        host: item.host,
      }
    },
    compareDates(begin, end) {
      // if (this.grant.type.type === 'personnel' && !begin) {
      //   return false
      // } else if (this.grant.type.type === 'personnel') {
      //   return true
      // }
      if (!begin || !end) {
        return false
      }
      const beginDate = new Date(this.getYear(begin), this.getMonth(begin), this.getDay(begin))
      const endDate = new Date(this.getYear(end), this.getMonth(end), this.getDay(end))
      if (beginDate.getTime() <= endDate.getTime()) {
        return true
      }

      return false
    },
    getDay(date) {
      return date.split(' ')[0]
    },
    getMonth(date) {
      const meses = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      return meses.indexOf(date.split(' ')[1])
    },
    getYear(date) {
      return date.split(' ')[2]
    },
    changeOpenEdit() {
      this.openEdit = !this.openEdit
      this.$emit('openEdit', this.openEdit)
    },
  },
}
</script>
