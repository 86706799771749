<template>
  <div class="card card--table">
    <div
      class="card-header d-flex align-items-center justify-content-between"
    >
      <div>
        <h4>
          <strong>{{ total }}</strong> <span v-html="title" />
        </h4>
        <div
          v-if="applicantsPath"
          class="form-check form-check-success form-switch d-flex align-items-center"
        >
          <input
            id="publishContent3"
            type="checkbox"
            class="form-check-input"
            value="1"
            @change="hideAssigneds"
          >
          <label
            class="form-check-label"
            for="publishContent3"
          ><small class="ms-1">Hide assigneds </small></label>
          <input
            id="publishContent2"
            type="checkbox"
            class="form-check-input ms-2"
            value="1"
            @change="showEvaluator"
          >
          <label
            class="form-check-label"
            for="publishContent2"
          ><small class="ms-1">Show evaluators </small></label>
        </div>
      </div>
      <div>
        <span
          v-if="filterPath && showActions"
          class="dis-ib"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Open filters"
          @click="openFilters()"
        ><a
          class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasFilters"
          aria-controls="offcanvasFilters"
        ><i data-feather="filter" /></a></span>

        <span
          v-if="repeatersView"
          class="dis-ib"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Repeaters view"
          @click="openRepeatersView()"
        ><a
          class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRepeaters"
          aria-controls="offcanvasRepeaters"
        ><i data-feather="users" /></a></span>

        <a
          v-if="exportPath"
          class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
          :class="{'d-none': exportLoading}"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Export to Excel"
          @click="exportConvos"
        ><i
          data-feather="file"
        /></a>
        <a
          v-if="exportPath"
          class="btn btn-icon btn-icon rounded-circle btn-flat-secondary loop-loading"
          :class="{'d-none': !exportLoading}"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Loading export..."
        ><i
          data-feather="loader"
        /></a>
        <span
          class="dis-ib"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Table settings"
        ><a
          class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasTableSettings"
          aria-controls="offcanvasTableSettings"
          @click="openTableSettings()"
        ><i data-feather="settings" /></a></span>
        <span
          v-if="actions && !applicantsPath && !applicants && showActions"
          class="dis-ib"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Multiple actions"
          @click="showActionsCanva"
        >
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >Multiple actions</button>
        </span>
      </div>
    </div>
    <div
      v-if="filterPath"
      id="usedFilters"
      class="card-body"
    >
      <div
        v-if="evaluatorsPath"
        class="alert alert-warning"
      >
        <div class="alert-body">
          <div class="row align-items-center">
            <div class="col-auto text-dark">
              <strong>{{ selectedItems.length }}</strong> {{ applicants ? 'applicants' : 'evaluators' }} selected and assigned to a group:
            </div>
            <div class="col-3">
              <v-select
                v-model="area"
                label="areaName"
                :options="[{areaName: 'Remove group', areaId: 0, remove: true}].concat(convo.areas)"
                :get-option-key="option => option.areaId"
              />
            </div>
            <div class="col-auto">
              <button
                class="btn btn-dark"
                @click="assignEvaluators"
              >
                <template v-if="!loadingApply">
                  Apply
                </template>
                <div
                  v-else
                  class="loading custom-loading"
                >
                  <div class="effect-1 effects" />
                  <div class="effect-2 effects" />
                  <div class="effect-3 effects" />
                </div>
              </button>
            </div>

            <div
              v-if="!applicants"
              class="col-auto"
            >
              <button
                type="button"
                class="btn btn-primary w-100"
                @click="showSendEvaluatorCredentials"
              >
                <span class="me-25 align-middle">Send credentials</span>
                <i data-feather="send" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="evaluatorsPath && applicants"
        class="alert alert-warning"
      >
        <div class="alert-body">
          <div class="row align-items-center">
            <div class="col-auto text-dark">
              <strong>{{ selectedItems.length }}</strong> {{ applicants ? 'applicants' : 'evaluators' }} selected and assigned to evaluators:
            </div>
            <div class="col-3 text-dark">
              <v-select
                v-model="selectedEvaluators"
                label="name"
                multiple
                :placeholder="'Type 3 or more characters to search...'"
                :options="evaluatorsTotal"
                :get-option-key="option => option.id"
                :reduce="item => item.id"
                @search="onSearch($event, 'users/fetchEvaluators')"
              />
            </div>
            <div class="col-auto">
              <button
                class="btn btn-dark"
                @click="assignTotalEvaluators"
              >
                <template v-if="!loadingApply">
                  Apply
                </template>
                <div
                  v-else
                  class="loading custom-loading"
                >
                  <div class="effect-1 effects" />
                  <div class="effect-2 effects" />
                  <div class="effect-3 effects" />
                </div>
              </button>
            </div>

            <div
              v-if="!applicants"
              class="col-auto"
            >
              <button
                type="button"
                class="btn btn-primary w-100"
                @click="showSendEvaluatorCredentials"
              >
                <span class="me-25 align-middle">Send credentials</span>
                <i data-feather="send" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <template v-if="Object.keys(watchFilters).filter(e => e != 'undefined').length > 0">
        Applied filters:
        <span
          v-for="filter, index in watchFilters"
          :key="index"
          class="badge badge-light-dark ms-1 mb-1"
        >
          <template v-if="Array.isArray(filter)">
            <template
              v-for="str in filter"
            >
              {{ str.name }}
              <a
                :key="str.name"
                class="ms-1 text-black"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Delete filter"
                @click="deleteFilter(index)"
              ><i data-feather="x" /></a>
            </template>
          </template>
          <template v-else-if="typeof filter === 'object' && filter && filter.name != 'All users'">
            {{ filter.name }}
            <a
              class="ms-1 text-black"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Delete filter"
              @click="deleteFilter(index)"
            ><i data-feather="x" /></a>
          </template>
          <template v-else-if="(filter && typeof filter !== 'object')">
            {{ filter }}
            <a
              class="ms-1 text-black"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Delete filter"
              @click="deleteFilter(index)"
            ><i data-feather="x" /></a>
          </template>
        </span>
      </template>
    </div>
    <div
      v-if="applicantsPath"
      class="card-body"
    >
      <div
        v-if="showEvaluators"
        class="stats__evaluators"
      >
        <ul class="list-group list-group-horizontal-sm">
          <li
            v-for="evaluator in evaluators"
            :key="evaluator.id"
            class="list-group-item"
          >
            {{ evaluator.name }} <span class="badge badge-light-success ms-1">{{ evaluator.applicants.length }}</span><br><span class="badge bg-light-secondary">{{ evaluator.erc ? evaluator.erc.code : '-' }}</span>
          </li>
        </ul>
      </div>
      <div class="alert alert-warning">
        <div class="alert-body">
          <div class="row align-items-center">
            <div class="col-auto text-dark">
              <strong>{{ selectedItems.length }}</strong> applicants selected and assigned to a evaluator:
            </div>
            <div class="col-auto">
              <a
                class="btn btn-dark"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasSelectEvaluators"
                aria-controls="offcanvasSelectEvaluators"
                @click="showActionsCanva"
              >Assign evaluators</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="table-responsive-sm"> -->
    <div>
      <SortingTable
        :sorted-field="sortedField"
        :data="data"
        :fetch-path="filterPath"
        :sort-mapping="sortMapping"
        :can-select="actions"
        :paginator-loading="paginatorLoading"
        :ranked-path="rankedPath"
        :selected-items="selectedItems"
        :allow-sort="allowSort"
        :show-actions-column="showActionsColumn"
        :actions-column-title="actionsColumnTitle"
        @selectAll="selectAll"
        @selectItem="selectItem"
        @togglePaginator="togglePaginator"
        @appliedFilters="appliedFilters($event)"
      >
        <template v-slot="{field}">
          <slot
            :field="field"
            :showInfoSheet="showInfoSheet"
          />
        </template>
      </SortingTable>
      <div
        v-if="!hideBottom"
        class="card-body"
      >
        <div
          v-if="total"
          class="d-flex align-items-center justify-content-between"
        >
          <Paginator
            :total="total"
            :store="filterPath"
            @loading="setLoading"
          />
          <div>
            <select
              v-model="perPage"
              class="form-select"
              @change="setPerPage"
            >
              <option value="15">
                15 items per page
              </option>
              <option value="50">
                50 items per page
              </option>
              <option value="75">
                75 items per page
              </option>
              <option value="10000">
                All items per page
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <ConvoFilter
      v-if="filterPath"
      :filter-path="filterPath"
      :filter-token="filterToken"
      @appliedFilters="appliedFilters($event)"
    >
      <template #filters>
        <slot
          name="customfilters"
        />
      </template>
    </ConvoFilter>
    <RepeatersView v-if="repeatersView" />
    <ItemInfoSheet :width="selectedType == 'templates' ? '50vw' : ''">
      <template #info-sheet-title>
        Information
      </template>
      <template #info-sheet>
        <slot
          v-if="selectedItem.id || applicantsPath"
          :item="selectedItem"
          :type="selectedType"
          name="info-sheet-item"
        />
        <slot
          v-else
          name="info-sheet-test"
        />
      </template>
    </ItemInfoSheet>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import Vue from 'vue'
import ConvoFilter from '../offcanvas/ConvoFilter.vue'
import Paginator from '../Paginator.vue'
import SortingTable from './SortingTable.vue'
import RepeatersView from '../offcanvas/RepeatersView.vue'
import ItemInfoSheet from '../offcanvas/ItemInfoSheet.vue'

export default {
  components: {
    ConvoFilter,
    RepeatersView,
    Paginator,
    SortingTable,
    ItemInfoSheet,
    vSelect,
  },
  props: {
    data: { type: [Array, Object], required: true },
    sortedField: { type: String, required: true },
    sortMapping: { type: Object, required: true },
    title: { type: String, required: true },
    total: { type: Number, required: false, default: null },
    fetchPath: { type: String, required: false },
    exportPath: { type: String, required: false, default: null },
    evaluatorsPath: { type: String, required: false, default: null },
    applicantsPath: { type: String, required: false, default: null },
    repeatersView: { type: Boolean, required: false, default: false },
    filterPath: { type: String, required: false, default: null },
    actions: { type: Boolean, required: false, default: false },
    showActions: { type: Boolean, required: false, default: true },
    hideBottom: { type: Boolean, required: false, default: false },
    applicants: { type: Boolean, required: false, default: false },
    rankedPath: { type: String, required: false, default: null },
    showActionsColumn: { type: Boolean, required: false, default: true },
    actionsColumnTitle: { type: String, required: false, default: 'Actions' },
    customFilters: { type: Object, required: false, default: null },
    allowSort: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      selectedItem: {},
      selectedItems: [],
      selectedType: null,
      area: null,
      showEvaluators: false,
      paginatorLoading: false,
      loadingApply: false,
      selectedEvaluators: [],
      filterToken: (Math.random() + 1).toString(36).substring(7),
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      filtersAux: 'filters/filters',
      watchFilters: 'filters/watchFilters',
      convo: 'convo/convo',
      evaluators: 'convo/evaluators',
      exportLoading: 'modals/exportLoading',
      evaluatorsTotal: 'users/evaluators',
      perPage: 'pagination/perPage',
    }),
    filters() {
      return this.customFilters ?? this.filtersAux
    },
  },
  methods: {
    hideAssigneds(event) {
      if (event.target.checked) {
        this.$store.dispatch('convo/fetchApplicants', { id: this.$route.params.id, status: 999 })
      } else {
        this.$store.dispatch('convo/fetchApplicants', { id: this.$route.params.id, status: null })
      }
    },
    openFilters() {
      this.$store.dispatch('modals/toggleConvoFilters', true)
      this.$store.dispatch('modals/toggleActiveFilterTab', this.filterToken)
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 250)
    },
    openRepeatersView() {
      this.$store.dispatch('modals/toggleConvoRepeatersView', true)
    },
    openTableSettings() {
      this.$store.dispatch('modals/toggleTableSettings', true)
    },
    async deleteFilter(index) {
      await this.$store.dispatch('pagination/savePerPage', 0)
      if (this.$route.params.action === 'all') {
        this.$emit('deleteFilter', index)
      } else {
        this.$store.dispatch('filters/deleteFilters', index)
        this.$emit('appliedFilters', true)
        this.paginatorLoading = true
        if (this.filterPath !== '-') {
          await this.$store.dispatch(this.filterPath, this.filters)
        }
        this.paginatorLoading = false
        setTimeout(() => {
          // eslint-disable-next-line no-undef
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 250)
      }
    },
    async exportConvos() {
      this.$store.dispatch('modals/toggleExportLoading', true)
      await this.$store.dispatch(this.exportPath, this.$store.getters['filters/filters'])
    },
    selectAll(items = null) {
      if (items) {
        this.selectedItems = items
      } else if (items === false) {
        this.selectedItems = []
      } else {
        this.selectedItems = [...this.data]
      }

      this.$emit('selectedItems', this.selectedItems)
    },
    appliedFilters() {
      this.$emit('appliedFilters', true)
    },
    async assignTotalEvaluators() {
      Vue.swal({
        title: 'Do you want to apply?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
      }).then(async result => {
        if (result.isConfirmed) {
          if (this.selectedEvaluators.length > 0 && this.selectedItems.length > 0) {
            this.loadingApply = true
            await this.$store.dispatch('convo/assignApplicants', {
              convoId: this.convo.id,
              applicants: this.selectedItems,
              evaluators: this.selectedEvaluators,
            })
            this.$toast.success('Evaluators assigned successfully')
            this.selectedEvaluators = []
            this.loadingApply = false

            // Load applicants again
            this.paginatorLoading = true
            await this.$store.dispatch('convo/fetchApplicants', { id: this.convo.id, status: null })
            this.paginatorLoading = false
            this.selectedItems = []
          } else {
            this.$toast.error('Select at least one evaluator and one applicant')
          }
        }
      })
    },
    async assignEvaluators() {
      Vue.swal({
        title: 'Do you want to apply?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
      }).then(async result => {
        if (result.isConfirmed) {
          this.loadingApply = true
          this.setLoading(true)
          if (this.selectedItems.length > 0) {
            if (this.applicants) {
              await this.$store.dispatch('convo/assignAppl', {
                convoId: this.convo.id,
                area: this.area,
                applicants: this.selectedItems,
              })
              this.$toast.success('Applicants assigned successfully')
            } else {
              await this.$store.dispatch(this.evaluatorsPath, {
                convoId: this.convo.id,
                area: this.area,
                evaluators: this.selectedItems,
              })
              this.$toast.success('Evaluators assigned successfully')
            }
            const { perPage } = this
            // Trigger pagination perpage change so SortingTable cleans selected items
            await this.$store.dispatch('pagination/savePerPage', 0)
            await this.$store.dispatch('pagination/savePerPage', perPage)
            this.area = null
            this.selectedItems = []
          }
          this.loadingApply = false
          this.setLoading(false)
        }
      })
    },
    selectItem(data) {
      if (data.click) {
        this.selectedItems.push(data.item)
      } else {
        this.selectedItems.splice(this.selectedItems.findIndex(x => x.id === data.item.id), 1)
      }

      this.$emit('selectedItems', this.selectedItems)
    },
    showEvaluator(event) {
      if (event.target.checked) {
        this.showEvaluators = true
      } else {
        this.showEvaluators = false
      }
    },
    showActionsCanva() {
      this.selectedItem.id = 1
      this.$store.dispatch('modals/toggleTableClicked', null)
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      this.$store.dispatch('modals/toggleTableClicked', null)

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    showSendEvaluatorCredentials() {
      this.selectedItem.id = null
      this.$store.dispatch('modals/toggleInfoItemSheet', true)

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    showInfoSheet(item, type = null) {
      this.selectedItem = item
      this.selectedType = type

      if (type === 'audience') {
        this.$store.dispatch('labourHazards/clearAudience')
      }
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    setLoading(loading) {
      this.paginatorLoading = loading
      this.$emit('appliedFilters', true)
    },
    togglePaginator(loading) {
      this.paginatorLoading = loading
    },
    async setPerPage(perPage) {
      this.selectedItems = []
      this.setLoading(true)
      this.$store.dispatch('pagination/clearPage')
      await this.$store.dispatch('pagination/savePerPage', perPage.target.value)
      if (this.filterPath) {
        await this.$store.dispatch(this.filterPath, this.filters)
      }
      this.setLoading(false)
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    async onSearch(search, store) {
      if (search !== '' && search.length > 2) {
        await this.$store.dispatch(store, { name: search, convo: this.convo.id })
      }
    },
  },
}
</script>
