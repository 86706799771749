<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <!-- <a
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :href="$router.resolve({name: 'researcher.memoir-profile.create', query: { actAs: $route.query.actAs }}).href"
              target="_blank"
            ><i data-feather="plus" /></a> -->
            <!-- <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Professional background settings"
            ><i data-feather="settings" /></a> -->
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="items"
              :sort-mapping="sortMapping"
              :title="'memoir profiles'"
              :total="itemsTotal"
              :fetch-path="'memoirsProfile/fetch'"
              :filter-path="'memoirsProfile/filter'"
              :actions="loggedUser.roles.includes('super-admin')"
              :allow-sort="true"
              @selectedItems="saveSelectedItems"
            >
              <template v-slot="{field}">
                <td class="text-end">
                  <!-- <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Edit area and group"
                  ><a
                    class="btn btn-icon rounded-circle btn-flat-dark"
                    :href="$router.resolve({name: 'admin.memoir-profile.edit', params: { id: field.id }, query: { actAs: $route.query.actAs } }).href"
                    target="_blank"
                  ><i data-feather="edit-3" /></a></span>
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Delete area & group"
                    @click="showDeleteModal(field.id)"
                  ><a
                    href="#"
                    class="btn btn-icon rounded-circle btn-flat-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#modalDeleteWithInput"
                  ><i data-feather="trash-2" /></a></span> -->
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item
                        :href="$router.resolve({ name: 'admin.memoir-profile-view.view', params: { id: field.id }, query: { actAs: $route.query.actAs } }).href"
                        target="_blank"
                      >
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View
                      </b-dropdown-item>
                      <b-dropdown-item
                        :href="$router.resolve({ name: 'researcher.memoir-profile.edit', params: { id: field.id }, query: { actAs: $route.query.actAs } }).href"
                        target="_blank"
                      >
                        <i
                          class="me-50"
                          data-feather="edit-3"
                        /> Edit
                      </b-dropdown-item>
                      <b-dropdown-item @click="showDeleteModal(field.id)">
                        <i
                          class="me-50"
                          data-feather="trash-2"
                        /> Delete <span class="float-end"><i data-feather="lock" /></span>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </td>
              </template>
              <template #customfilters>
                <div
                  v-if="loggedUser.roles.includes('super-admin')"
                  class="mb-1"
                >
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Researcher</label>
                  <v-select
                    v-model="filters.researcher"
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event, role: 'researcher' }, 'users/fetchFakeUsers')"
                    @input="saveFilter({ customLabel: true, name: `Researcher: ${$event ? $event.name : ''}`, value: $event }, 'researcher')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Year</label>
                  <date-picker
                    v-model="filters['year']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `Year: ${$event}`, value: `${$event}` }, 'year')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Areas</label>
                  <v-select
                    v-if="areas.length !== undefined"
                    v-model="filters.areas"
                    multiple
                    label="name"
                    :options="areas"
                    :get-option-key="option => option.id"
                    @input="saveFilter({ customLabel: true, name: `Areas: ${$event ? ($event.map(e => e.name)).join(' - ') : ''}`, value: $event }, 'areas')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All areas
                    </option>
                  </v-select>
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Host</label>
                  <v-select
                    v-model="filters.host"
                    label="custom_name"
                    :options="hosts"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'institutions/filter')"
                    @input="saveFilter({ customLabel: true, name: `Host: ${$event ? $event.custom_name : ''}`, value: $event }, 'host')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="name"
                    class="form-label"
                  >Status</label>
                  <v-select
                    v-model="filters.status"
                    label="name"
                    :options="statuses"
                    :get-option-key="option => option.name"
                    @input="saveFilter({ customLabel: true, name: `Status: ${$event ? $event.name : ''}`, value: $event }, 'status')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >On promotion</label>
                  <select
                    id=""
                    v-model="filters.promo"
                    name=""
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'On promotion: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'promo')"
                  >
                    <option value="">
                      All
                    </option>
                    <option value="true">
                      Yes
                    </option>
                    <option value="false">
                      No
                    </option>
                  </select>
                </div>
              </template>
              <template #info-sheet-item>
                <div class="offcanvas-body offcanvas-body--view">

                  <h4 class="mb-2">
                    <strong class="text-success">{{ selectedItems.length }}</strong> selected registers
                  </h4>

                  <ul
                    id="basic-list-group"
                    class="list-group"
                  >
                    <li
                      v-for="(status, index) in statuses"
                      :key="index"
                      class="list-group-item draggable"
                    >
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              :id="`tableAction${index}`"
                              v-model="actions.changeStatus"
                              type="radio"
                              :value="status.id"
                              class="form-check-input"
                            >
                            <label
                              class="form-check-label"
                              :for="`tableAction${index}`"
                            >Check status to {{ status.status }}</label>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item draggable">
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              id="dateAction"
                              v-model="actions.addSpecialAccess.status"
                              type="checkbox"
                              class="form-check-input"
                              @change="!$event.target.checked ? actions.addSpecialAccess.value = null : ''"
                            >
                            <label
                              class="form-check-label"
                              for="dateAction"
                            >Add special access</label>
                            <!-- <date-picker
                              v-if="actions.addSpecialAccess.status"
                              v-model="actions.addSpecialAccess.value"
                              format="D MMM Y"
                              value-type="format"
                              class="w-100"
                            /> -->
                          </div>
                        </div>
                      </div>
                    </li>
                    <div
                      v-if="actions.addSpecialAccess.status"
                      class="aux-clandar"
                    >
                      <date-picker
                        v-model="actions.addSpecialAccess.value"
                        format="D MMM Y"
                        value-type="format"
                        class="w-100"
                      />
                    </div>
                  </ul>
                </div>
                <div class="offcanvas-footer mt-auto">
                  <a
                    v-if="!sending"
                    title="apply actions"
                    class="btn btn-primary mb-1 d-grid w-100"
                    @click="applyActions"
                  >Apply actions</a>
                  <button
                    v-if="sending"
                    class="btn btn-dark mb-1 w-100"
                    type="button"
                    disabled=""
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span class="ms-25 align-middle">Applying...</span>
                  </button>
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import vSelect from 'vue-select'
import CompleteTable from '../../../partials/components/CompleteTable.vue'
import 'vue2-datepicker/index.css'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    DatePicker,
    vSelect,
  },
  data() {
    return {
      sending: false,
      selectedItems: [],
      actions: {
        changeStatus: null,
        addSpecialAccess: {
          status: false,
          value: null,
        },
      },
      sortedField: 'Researcher',
      defaultFields: [
        {
          name: 'Researcher',
          checked: true,
          order: 1,
          only_admin: true,
        },
        {
          name: 'Year',
          checked: true,
          order: 2,
        },
        {
          name: 'Name memoir',
          checked: true,
          order: 3,
        },
        {
          name: 'Area',
          checked: true,
          order: 4,
        },
        {
          name: 'Host 1',
          checked: true,
          order: 5,
        },
        {
          name: 'Host 2',
          checked: true,
          order: 6,
        },
        {
          name: 'Email',
          checked: true,
          order: 7,
        },
        {
          name: 'Status',
          checked: true,
          order: 8,
        },
        {
          name: 'Special access',
          checked: true,
          order: 8,
          only_admin: true,
        },
      ],
      sortMapping: {
        Researcher: 'user.true_name',
        Year: 'year',
        'Name memoir': 'name_on_memoir',
        Area: 'area.code',
        'Host 1': 'host_1.code',
        'Host 2': 'host_2.code',
        Email: 'user.email',
        Status: 'status.status_table',
        'Special access': 'special_access',
      },
      title: 'Memoir profiles',
    }
  },
  computed: {
    ...mapGetters({
      filters: 'filters/filters',
      fields: 'modals/tableSettingsFields',
      items: 'memoirsProfile/items',
      itemsTotal: 'memoirsProfile/itemsTotal',
      users: 'users/fakeUsers',
      loggedUser: 'auth/admin',
      areas: 'areas/areas',
      hosts: 'institutions/institutions',
      statuses: 'status/statuses',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'memoir_profiles')
    await this.$store.dispatch('memoirsProfile/filter', this.$store.getters['filters/filters'])
    await this.$store.dispatch('memoirsProfile/setRouteName', 'researcher.memoir-profile.index')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'memoir_profiles',
      })
    }
    if (this.areas.length === 0) {
      this.$store.dispatch('areas/filter', this.$store.getters['filters/filters'])
    }
    this.$store.dispatch('status/filterStatus', 'Memoirs profiles')
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    saveSelectedItems(items) {
      this.selectedItems = items
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'memoirsProfile', url: `${Vue.prototype.$groupUrl}/memoirs-profiles/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
    async onSearch(search, store) {
      const s = search
      s.active = true
      if (s.name !== '') {
        await this.$store.dispatch(store, s)
      }
    },
    applyActions() {
      if (this.selectedItems.length > 0) {
        Vue.swal({
          title: 'Perform the following actions?',
          html: this.getActionsString(),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes, apply!',
        }).then(result => {
          if (result.isConfirmed) {
            const itemIds = this.selectedItems.map(el => el.id)
            this.$store.dispatch('memoirsProfile/applyActions', { actions: this.actions, itemIds }).then(() => {
              this.$store.dispatch('modals/toggleInfoItemSheet', false)
              this.$toastr.success('', 'Actions applied successfully!')
              this.$store.dispatch('memoirsProfile/filter', this.filters)
              this.actions = {
                changeStatus: null,
                addSpecialAccess: {
                  status: false,
                  value: null,
                },
              }
            })
          }
        })
      } else {
        Vue.swal('No items selected', '', 'warning')
      }
    },
    getActionsString() {
      let str = ''

      if (this.actions.changeStatus) {
        str += '<p class="text-success">Change status</p><br>'
      }

      if (this.actions.addSpecialAccess.status) {
        str += '<p class="text-success">Add special access</p>'
      }

      return str
    },
  },
}
</script>
