<!-- eslint-disable vue/html-self-closing -->
<template>
  <div class="">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <div class="form-group">
            <label
              class="form-label"
              for="select2-institution"
            >Select the institution</label>
            <!-- <a @click="showInput(false)">The institution is not in the dropdown</a> -->
            <select
              v-if="institution.listed"
              v-model="institution.value"
              class="form-control"
              :disabled="institution.status == 2 || !convo.open_applicants"
              :value="institution.value"
              @change="submitAnswer()"
            >
              <option
                value=""
              >
                Select an option
              </option>
              <option
                v-for="option in parameter.values"
                :key="option.id"
                :value="option.id"
              >
                {{ option.default_value }}
              </option>
            </select>
            <!-- este input sale tras hacer clic en el enlace de mi institucion no está en el listado. cambiar el select por el input group -->
            <div
              v-if="!institution.listed"
              class="input-group input-group-merge mb-2"
            >
              <input
                v-model="institution.value"
                class="form-control"
                type="tel"
                :placeholder="
                  parameter.attributes ? parameter.attributes.placeholder : ''
                "
                @change="submitAnswer()"
              />
              <a
                class="input-group-text cursor-pointer"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Cancel"
                @click="showInput(true)"
              ><i data-feather="x"></i></a>
            </div>
            <!--  -->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        v-if="institution.status != 2"
        class="col"
      >
        <div
          v-if="institution.value"
          class="demo-inline-spacing"
        >
          <input
            id="copy-to-clipboard-input"
            type="hidden"
            class="form-control"
            value="aaa"
          />
          <a
            v-if="link"
            id="btn-copy"
            href="javascript:void(0)"
            class="btn btn-outline-success mt-0"
            @click="copyClipboard(link)"
          ><i data-feather="copy"></i> Copy link</a>
        </div>
      </div>
      <div
        v-if="loggedUser && loggedUser.roles && loggedUser.roles.includes('super-admin') && institution.status == 2"
        class="col"
      >
        <div
          class="demo-inline-spacing"
        >
          <a
            id="btn-copy"
            href="javascript:void(0)"
            class="btn btn-outline-success mt-0"
            @click="checkLetter()"
          ><i data-feather="external-link"></i> View file</a>
        </div>
      </div>
      <div class="col text-end">
        <p class="mt-0 mb-0">
          <small>Status:</small>
        </p>
        <p>
          <span
            id="status1"
            :class="{'d-none': institution.status != 0}"
            class="text-dark"
          ><i data-feather="unlock"></i> Pending select institution</span>
          <span
            id="status2"
            :class="{'d-none': institution.status != 1}"
            class="text-danger"
          ><i data-feather="coffee"></i> Click the copy link button and send the link to the institution by email</span>
          <span
            id="status2"
            :class="{'d-none': institution.status != 2}"
            class="text-success"
          ><i data-feather="check-circle"></i> File uploaded</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as clipboard from 'clipboard-polyfill'
import Vue from 'vue'

export default {
  components: {},
  props: {
    answer: {},
    parameter: { type: Object, required: true },
    groupId: { type: Number, required: false, default: 0 },
    sectionId: { type: Number, required: true },
    userId: { type: Number, required: false, default: null },
  },
  data() {
    return {
      institution: {
        status: 0,
        listed: true,
        value: '',
        sent: false,
      },
      link: '',
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      payload: 'convoSection/payload',
      loggedUser: 'auth/admin',
    }),
  },
  async mounted() {
    this.replaceIcons()

    if (this.answer && this.answer.value) {
      const parsedAnswer = JSON.parse(this.answer.value)
      this.institution = parsedAnswer
      this.link = await this.generateLink()
    }
  },
  methods: {
    checkLetter() {
      window.open(`${Vue.prototype.$groupUrl}/parameter/check-institution-file/${this.answer.id}?token=${localStorage.getItem('accessTokenAdmin')}`, '_blank')
    },
    replaceIcons() {
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    async submitAnswer() {
      if (this.institution.status !== 2) {
        if (this.institution.status === 0) {
          this.institution.status = 1
          this.link = await this.generateLink()
        }

        if (this.institution.value == '') {
          this.institution.status = 0
        }

        this.$emit('submitAnswer', JSON.stringify(this.institution))
      }
    },
    showInput(show) {
      this.institution.listed = show
      this.institution.value = ''
      this.replaceIcons()
    },
    async generateLink() {
      await this.$store.dispatch('convoSection/getInstitutionUrl', {
        parameterId: this.parameter.id,
        convoId: this.convo.id,
        groupId: this.groupId,
        sectionId: this.sectionId,
        userId: this.userId,
      })

      const urlPayload = this.$router.resolve({
        name: 'front.senior-call-upload-institution',
        params: {
          payload: this.payload,
        },
      })

      return new URL(urlPayload.href, window.location.origin).href
    },
    async copyClipboard(text) {
      clipboard.writeText(text).then(
        () => {
          this.$toastr.success('', 'Copied to clipboard')
        },
        () => {
          this.$toastr.error('', 'Failed to clipboard')
        },
      )
    },
  },
}
</script>
