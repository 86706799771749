<template>
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div class="content-body content-body--senior-call">
          <CompleteTable
            :sorted-field="sortedField"
            :data="users"
            :sort-mapping="sortMapping"
            :title="'evaluators'"
            :total="usersTotal"
            :fetch-path="'users/evaluatorsList'"
            :export-path="''"
            :filter-path="'users/filterEvaluatorsList'"
            :allow-sort="true"
          >
            <template v-slot="{field}">
              <td
                class="text-end"
              >
                <b-dropdown
                  variant="link"
                  no-caret
                  toggle-class="p-0"
                  right
                >
                  <template #button-content>
                    <i data-feather="more-vertical" />
                  </template>
                  <div
                    class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                  >
                    <b-dropdown-item
                      :href="$router.resolve({ name: 'admin.calls.evaluators.view', params: { id: field.id, action: 'all' }, query: { actAs: $route.query.actAs } }).href"
                      target="_blank"
                    >
                      <i
                        class="me-50"
                        data-feather="eye"
                      /> All
                    </b-dropdown-item>
                    <b-dropdown-item
                      :href="$router.resolve({ name: 'admin.calls.evaluators.view', params: { id: field.id, action: 'senior-call' }, query: { actAs: $route.query.actAs } }).href"
                      target="_blank"
                    >
                      <i
                        class="me-50"
                        data-feather="eye"
                      /> Senior Call
                    </b-dropdown-item>
                    <b-dropdown-item
                      :href="$router.resolve({ name: 'admin.calls.evaluators.view', params: { id: field.id, action: 'academia-call' }, query: { actAs: $route.query.actAs } }).href"
                      target="_blank"
                    >
                      <i
                        class="me-50"
                        data-feather="eye"
                      /> Academia Call
                    </b-dropdown-item>
                    <b-dropdown-item
                      :href="$router.resolve({ name: 'admin.calls.evaluators.view', params: { id: field.id, action: 'as-pc-member' }, query: { actAs: $route.query.actAs } }).href"
                      target="_blank"
                    >
                      <i
                        class="me-50"
                        data-feather="eye"
                      /> AS PC member
                    </b-dropdown-item>
                    <b-dropdown-item
                      :href="$router.resolve({ name: 'admin.calls.evaluators.view', params: { id: field.id, action: 'as-promotion-peer' }, query: { actAs: $route.query.actAs } }).href"
                      target="_blank"
                    >
                      <i
                        class="me-50"
                        data-feather="eye"
                      /> AS Promotion peer
                    </b-dropdown-item>
                    <b-dropdown-item
                      :href="$router.resolve({ name: 'admin.calls.evaluators.view', params: { id: field.id, action: 'as-promotion-host' }, query: { actAs: $route.query.actAs } }).href"
                      target="_blank"
                    >
                      <i
                        class="me-50"
                        data-feather="eye"
                      /> AS Promotion host
                    </b-dropdown-item>
                    <b-dropdown-item
                      :href="$router.resolve({ name: 'admin.users.edit', params: { id: field.id }, query: { actAs: $route.query.actAs } }).href"
                      target="_blank"
                    >
                      <i
                        class="me-50"
                        data-feather="edit-3"
                      /> Edit profile
                    </b-dropdown-item>
                    <b-dropdown-item @click="showDeleteModal(field.id)">
                      <i
                        class="me-50"
                        data-feather="trash-2"
                      /> Delete profile
                    </b-dropdown-item>
                  </div>
                </b-dropdown>
                <a
                  href=""
                  class="btn btn-icon btn-light-secondary d-block d-sm-none"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasMenu"
                  aria-controls="offcanvasBottom"
                ><i
                  data-feather="more-vertical"
                /></a>
              </td>
            </template>
            <template #customfilters>
              <div class="mb-1">
                <label
                  for="name"
                  class="form-label"
                >Evaluator name</label>
                <input
                  v-model="filters.name"
                  type="text"
                  class="form-control"
                  name="name"
                  @change="saveFilter({ customLabel: true, name: `Evaluator Name: ${$event.target.value}`, value: `${$event.target.value}` }, 'name')"
                >
              </div>
              <div class="mb-1">
                <label
                  for="name"
                  class="form-label"
                >Active</label>
                <v-select
                  v-model="filters.active"
                  label="label"
                  :options="[
                    {label: 'Yes', name: 'Yes', value: true},
                    {label: 'No', name: 'No', value: false},
                    {label: 'All', name: 'All', value: null},
                  ]"
                  :get-option-key="option => option.name"
                  @input="saveFilter({ customLabel: true, name: `Active: ${$event ? $event.name : ''}`, value: $event }, 'active')"
                />
              </div>
              <div class="mb-1">
                <label
                  for="name"
                  class="form-label"
                >Areas</label>
                <v-select
                  v-model="filters.areaFromArray"
                  multiple
                  label="name"
                  :options="areas"
                  :get-option-key="option => option.name"
                  @input="saveFilter({ customLabel: true, name: `Areas: ${$event ? ($event.map(e => e.name)).join(' - ') : ''}`, value: $event }, 'areaFromArray')"
                  @search="onSearch({ name: $event }, 'areas/filter')"
                />
              </div>
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >ERC code</label>
                <v-select
                  v-model="filters.erc_main"
                  label="name"
                  :options="ercs"
                  :get-option-key="option => option.id"
                  @input="saveFilter({ customLabel: true, name: `ERC Code: ${$event ? $event.name : ''}`, value: $event }, 'erc_main')"
                  @search="onSearch({ name: $event }, 'erc/filter')"
                />
              </div>
              <div class="mb-1">
                <label
                  for="name"
                  class="form-label"
                >Host institution</label>
                <v-select
                  v-model="filters.hostFromArray"
                  multiple
                  label="name"
                  :options="institutions"
                  :get-option-key="option => option.name"
                  @input="saveFilter({ customLabel: true, name: `Host institution: ${$event ? ($event.map(e => e.name)).join(' - ') : ''}`, value: $event }, 'hostFromArray')"
                  @search="onSearch({ name: $event }, 'institutions/filter')"
                />
              </div>
              <div class="mb-1">
                <label
                  for="name"
                  class="form-label"
                >Evaluator in Senior Call</label>
                <v-select
                  v-model="filters.evaluationsOnSenior"
                  label="label"
                  :options="[
                    {label: 'Yes', name: 'Yes', value: true},
                    {label: 'No', name: 'No', value: false},
                  ]"
                  :get-option-key="option => option.name"
                  @input="saveFilter({ customLabel: true, name: `Evaluator in Senior Call: ${$event ? $event.name : ''}`, value: $event }, 'evaluationsOnSenior')"
                />
              </div>
              <div class="mb-1">
                <label
                  for="name"
                  class="form-label"
                >Evaluator in Academia Call</label>
                <v-select
                  v-model="filters.evaluationsOnAcademia"
                  label="label"
                  :options="[
                    {label: 'Yes', name: 'Yes', value: true},
                    {label: 'No', name: 'No', value: false},
                  ]"
                  :get-option-key="option => option.name"
                  @input="saveFilter({ customLabel: true, name: `Evaluator in Academia Call: ${$event ? $event.name : ''}`, value: $event }, 'evaluationsOnAcademia')"
                />
              </div>
              <div class="mb-1">
                <label
                  for="name"
                  class="form-label"
                >Evaluator in Promotion Call</label>
                <v-select
                  v-model="filters.evaluationsOnPromotion"
                  label="label"
                  :options="[
                    {label: 'Yes', name: 'Yes', value: true},
                    {label: 'No', name: 'No', value: false},
                  ]"
                  :get-option-key="option => option.name"
                  @input="saveFilter({ customLabel: true, name: `Evaluator in Promotion Call: ${$event ? $event.name : ''}`, value: $event }, 'evaluationsOnPromotion')"
                />
              </div>
              <div class="mb-1">
                <label
                  for="name"
                  class="form-label"
                >Evaluator in Call</label>
                <v-select
                  v-model="filters.evaluationsOnCall"
                  multiple
                  label="title"
                  :options="convos"
                  :get-option-key="option => option.id"
                  @input="saveFilter({ customLabel: true, name: `Evaluator in Call: ${$event ? ($event.map(e => e.title)).join(' - ') : ''}`, value: $event }, 'evaluationsOnCall')"
                />
              </div>
              <!-- FATLA PUBLIC PROFILE YES O NO -->
              <!-- <div class="mb-1">
                <label
                  for="name"
                  class="form-label"
                >Applicant evaluated</label>
                <v-select
                  label="name"
                  :options="auxUsers"
                  :get-option-key="option => option.id"
                  placeholder="Type to search..."
                  @search="onSearch({ name: $event, role: 'researcher' }, 'users/fetchFakeUsers')"
                  @input="saveFilter($event, 'applicantEvalated')"
                />
              </div> -->
            </template>
          </CompleteTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import CompleteTable from '../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      title: 'Evaluators',
      sortedField: 'Name',
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Active',
          checked: true,
          order: 2,
        },
        {
          name: 'Assigned',
          checked: true,
          order: 3,
        },
        {
          name: 'Area',
          checked: true,
          order: 4,
        },
        {
          name: 'ERC Codes',
          checked: true,
          order: 5,
        },
        {
          name: 'Institution',
          checked: true,
          order: 6,
        },
        {
          name: 'Senior Calls',
          checked: true,
          order: 7,
        },
        {
          name: 'Academy Calls',
          checked: true,
          order: 8,
        },
        {
          name: 'As PC Member',
          checked: true,
          order: 9,
        },
        {
          name: 'As Promotion Peer',
          checked: true,
          order: 10,
        },
        {
          name: 'As Promotion Host',
          checked: true,
          order: 11,
        },
        {
          name: 'Public profile',
          checked: true,
          order: 12,
        },
        {
          name: 'Last login',
          checked: true,
          order: 13,
        },
      ],
      sortMapping: {
        Name: 'name',
        Active: 'active',
        Assigned: 'assigned_evaluator',
        Area: 'area',
        'ERC Codes': 'ercs',
        Institution: 'institution',
        'Senior Calls': 'senior_calls_table',
        'Academy Calls': 'academy_calls_table',
        'As PC Member': 'as_pc_member',
        'As Promotion Peer': 'as_promotion_peer',
        'As Promotion Host': 'as_promotion_host',
        'Public profile': 'public_profile',
        'Last login': 'last_login',
      },
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      users: 'users/evaluatorsList',
      usersTotal: 'users/evaluatorsListTotal',
      filters: 'filters/filters',
      areas: 'areas/areas',
      ercs: 'erc/items',
      institutions: 'institutions/institutions',
      convos: 'convo/convos',
      auxUsers: 'users/fakeUsers',
    }),
  },
  async mounted() {
    await this.$store.dispatch('users/filterEvaluatorsList', this.filters)
    await this.$store.dispatch('modals/fetchUserFields', 'evaluatorsList')
    await this.$store.dispatch('convo/fetchAll')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'evaluatorsList',
      })

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
    }
  },
  methods: {
    async saveFilter(value, field) {
      if (field === 'evaluationsOnCall') {
        value.value.map(e => {
          e.name = e.title
          return e
        })
      }
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    async onSearch(search, store) {
      if (search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch(store, search)
      }
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'users/fetchUsers', url: `${Vue.prototype.$groupUrl}/user/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
