<!-- eslint-disable vue/html-self-closing -->
<template>
  <div class="card">
    <fieldset>
      <div
        v-if="group.name"
        v-b-toggle="`collapseGroup-${group.id}`"
        class="card-header"
      >
        <legend>{{ group.name }}</legend>
        <p
          v-if="group.description && group.description != ''"
          v-html="group.description"
        />
      </div>
      <b-collapse
        :id="`collapseGroup-${group.id}`"
        visible
      >
        <div
          v-if="(group.type === 'list' || group.type === 'list-mail') && parameters"
          class="row"
        >
          <ParameterList
            :group-id="group.related_group_id"
            :section-id="sectionId"
            :headers="parameters"
            :mail="group.type === 'list-mail'"
            :status="group.status"
            :save-answer="saveAnswer"
            :user-id="userId"
          />
        </div>
        <div
          v-else
          class="card-body"
          style="padding-top: 0px;"
        >
          <div
            class="row"
          >
            <Parameter
              v-for="(parameter) in parameters"
              :key="parameter.id"
              :parameter="parameter"
              :answer="parameter.answer"
              :group-id="group.id"
              :section-id="sectionId"
              :save-answer="saveAnswer"
              :user-id="userId"
            />
          </div>
        </div>
      </b-collapse>
    </fieldset>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import Parameter from './Parameter.vue'
import ParameterList from './ParameterList.vue'

export default {
  components: {
    Parameter,
    ParameterList,
    BCollapse,
  },
  props: {
    group: { type: Object, required: true },
    sectionId: { type: Number, required: true },
    convoId: { type: [Number, String], required: false, default: undefined },
    saveAnswer: { type: Boolean, required: false, default: true },
    userId: { type: Number, required: false, default: null },
  },
  data() {
    return {
      parameters: null,
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    async fetch() {
      await this.$store.dispatch('convoGroup/fetchParameters', [this.group.id, this.convoId, this.sectionId])

      this.parameters = this.$store.getters['convoGroup/parameters']

      let requiredCount = 0
      let requiredFilled = 0

      if (this.group.type === 'list-mail') {
        requiredCount = 4
        requiredFilled = this.parameters[0].answer ? Object.keys(JSON.parse(this.parameters[0].answer.value)).length : 0
      } else if (this.group.type === 'list' && !this.group.name.includes('ERC')) {
        requiredCount = 1

        if (this.parameters[0].answer) {
          let count = false
          Object.keys(JSON.parse(this.parameters[0].answer.value)).forEach(key => {
            if (JSON.parse(this.parameters[0].answer.value)[key] == 23) {
              count = true
            }
          })
          requiredFilled = count ? 1 : 0
        }
      } else if (this.group.type !== 'list') {
        this.parameters.forEach(parameter => {
          if (parameter.is_required) {
            requiredCount += 1

            if (parameter.answer && parameter.answer.value) {
              requiredFilled += 1
            }
          }
        })
      }

      this.$store.dispatch('convoSection/calculatePercentage', [requiredCount, requiredFilled])
    },
  },
}
</script>
