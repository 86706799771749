<template>
  <div>
    <div v-if="user.id && $route.name != 'admin.login'">
      <Header />
      <ResearcherMenu v-if="user.roles_all.some(e => e.researcher === true) || user.roles.includes('super-admin')" />
      <UniversityMenu v-if="user.roles.includes('university') || user.roles.includes('cae')" />
      <AwardedMenu v-if="user.roles.includes('awarded')" />
      <OndeuevMenu v-if="user.roles.includes('ondeuev')" />
      <EvaluatorMenu v-if="user.roles_all.some(e => e.evaluator === true) && !user.roles_all.some(e => e.researcher === true)" />
      <Menu v-if="user.roles.includes('super-admin')" />
    </div>
    <router-view
      @toggleModal="toggleModal"
    />
    <delete-modal
      :name="name"
      :url="modalUrl"
      :show="showModal == 'delete'"
      :required-fetch="requiredFetch"
      @closeModal="closeModal"
    />
    <reorganize-after-delete />
    <OTP />
    <AddFavorite />
    <ReportModal />
    <AddRequestFormModal v-if="user.id && user.roles.includes('super-admin')" />
    <CreateAlert v-if="$route.name !== 'researcher.dashboard'">
      <template #title>
        Create Notification / Task
      </template>
      <template #create-alert>
        <OffcanvaBody />
      </template>
    </CreateAlert>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CreateAlert from '@/views/back/partials/offcanvas/CreateAlert.vue'
import OffcanvaBody from '@/views/back/admin/alerts/components/OffcanvaBody.vue'
import Header from '../../views/back/partials/Header.vue'
import Menu from '../../views/back/partials/Menu.vue'
import ResearcherMenu from '../../views/back/partials/ResearcherMenu.vue'
import UniversityMenu from '../../views/back/partials/UniversityMenu.vue'
import AwardedMenu from '../../views/back/partials/AwardedMenu.vue'
import EvaluatorMenu from '../../views/back/partials/EvaluatorMenu.vue'
import DeleteModal from '../../views/back/partials/modals/DeleteModal.vue'
import ReorganizeAfterDelete from '../../views/back/partials/modals/ReorganizeAfterDelete.vue'
import AddFavorite from '../../views/back/partials/offcanvas/AddFavorite.vue'
import AddRequestFormModal from '../../views/back/partials/offcanvas/AddRequestFormModal.vue'
import OTP from '../../views/back/partials/modals/OTP.vue'
import ReportModal from '../../views/back/partials/modals/ReportModal.vue'
import OndeuevMenu from '../../views/back/partials/OndeuevMenu.vue'

// import core styles
require('@core/scss/core.scss')
require('@core/scss/base/pages/app-todo.scss')
require('@core/scss/vue/libs/vue-sweetalert.scss')

// import assets styles
require('@/assets/scss/components.scss')
require('@/assets/scss/style.scss')
require('@/assets/scss/quill.scss')
require('@/assets/scss/vertical_menu.scss')
require('@/assets/scss/vue-select.scss')

export default {
  components: {
    Header,
    Menu,
    DeleteModal,
    ResearcherMenu,
    ReorganizeAfterDelete,
    OTP,
    EvaluatorMenu,
    AddFavorite,
    ReportModal,
    UniversityMenu,
    AwardedMenu,
    CreateAlert,
    OndeuevMenu,
    AddRequestFormModal,
    OffcanvaBody,
  },
  data() {
    return {
      showModal: false,
      modalUrl: '',
      name: '',
      requiredFetch: true,
      customMessage: '',
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/admin',
      users: 'users/fakeUsers',
    }),
  },
  mounted() {
    if (this.$route.name !== 'admin.publications.import') {
      window.onfocus = async () => {
        this.$store.dispatch('filters/saveCurrentRoute', this.$router.currentRoute)
        this.$store.dispatch('modals/saveCurrentRoutePermission', this.$router.currentRoute.meta.requiresPermission ?? null)
        this.$store.dispatch('pagination/saveCurrentRoute', this.$router.currentRoute)
      }
    }
    setInterval(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 300)
  },
  methods: {
    toggleModal(data) {
      this.showModal = data.type
      this.modalUrl = data.url
      this.name = data.name
      this.requiredFetch = data.requiredFetch
      this.customMessage = data.customMessage
    },
    async uploadFile(name) {
      const file = this.$refs[name].files[0]

      const fileData = {
        name: file.name,
        original_name: file.name,
        description: '',
        weight: (file.size / 1024 / 1024).toFixed(2),
        extension: file.type,
        created_at: 'Pending to save...',
        to: '',
        file,
        url: URL.createObjectURL(file),
        aux: name,
        user_id: this.user.id,
      }

      this.alert.file = fileData
    },
    closeModal() {
      this.showModal = false
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
  },
}
</script>
