<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                Publications
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              v-if="hasPermission('create publications')"
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :href="$router.resolve({ name: 'researcher.publications.create', query: { actAs: $route.query.actAs } }).href"
              target="_blank"
            ><i data-feather="plus" /></a>
            <a
              v-if="loggedUser.roles.includes('super-admin') || loggedUser.roles.includes('publication-admin')"
              class="btn btn-icon btn-dark ms-25"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="import WOS publications"
              :href="$router.resolve({ name: 'admin.publications.import', query: { actAs: $route.query.actAs } }).href"
              target="_blank"
            ><i data-feather="upload-cloud" /></a>
            <!-- <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Research lines settings"
            ><i data-feather="settings" /></a> -->
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <PublicationStats
              v-if="!hide"
            />
            <CompleteTable
              :sorted-field="sortedField"
              :data="publications"
              :sort-mapping="sortMapping"
              :title="'publications'"
              :total="publicationsTotal"
              :fetch-path="'publications/fetch'"
              :filter-path="'publications/filter'"
              :actions="loggedUser.roles.includes('super-admin') || loggedUser.roles.includes('publication-admin')"
              :export-path="'publications/export'"
              :allow-sort="true"
              @selectedItems="saveSelectedItems"
            >
              <template v-slot="{field}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item
                        :href="$router.resolve({ name: 'researcher.dashboard', params: { id: field.user.id }, query: { actAs: $route.query.actAs } }).href"
                        target="_blank"
                      >
                        <i
                          class="me-50"
                          data-feather="user"
                        /> View researcher profile
                      </b-dropdown-item>
                      <div
                        v-if="hasPermission('update publications')"
                        class="dropdown-divider"
                      />
                      <b-dropdown-item
                        v-if="hasPermission('update publications')"
                        :href="$router.resolve({name: 'researcher.publications.edit', params: { id: field.id }, query: { actAs: $route.query.actAs }}).href"
                        target="_blank"
                      >
                        <i
                          class="me-50"
                          data-feather="edit-3"
                        /> Edit
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="hasPermission('delete publications')"
                        @click="showDeleteModal(field.id)"
                      >
                        <i
                          class="me-50"
                          data-feather="trash-2"
                        /> Delete <span class="float-end"><i data-feather="lock" /></span>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                  <a
                    href=""
                    class="btn btn-icon btn-light-secondary d-block d-sm-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasBottom"
                  ><i
                    data-feather="more-vertical"
                  /></a>
                </td>
              </template>
              <template #customfilters>
                <div
                  v-if="hasAdminPermission('view publications')"
                  class="mb-1"
                >
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Researcher</label>
                  <v-select
                    v-model="filters.researcher"
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                    @input="saveFilter({ customLabel: true, name: `Researcher: ${$event ? $event.name : ''}`, value: $event }, 'researcher')"
                  />
                  <hr>
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Active</label>
                  <select
                    v-model="filters.active"
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Active: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'active')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All
                    </option>
                    <option
                      value="true"
                    >
                      Yes
                    </option>
                    <option
                      value="false"
                    >
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >ICREA active</label>
                  <select
                    id=""
                    v-model="filters.researcher_active"
                    name=""
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Icrea active: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'researcher_active')"
                  >
                    <option value="">
                      All
                    </option>
                    <option value="true">
                      Yes
                    </option>
                    <option value="false">
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Type</label>
                  <v-select
                    v-model="filters.publication_type"
                    label="name"
                    :options="types"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'publicationTypes/filter')"
                    @input="saveFilter({ customLabel: true, name: `Type: ${$event ? $event.name : ''}`, value: $event }, 'publication_type')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Reference publication</label>
                  <input
                    v-model="filters.full_reference"
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter({ customLabel: true, name: `Reference publication: ${$event.target.value}`, value: `${$event.target.value}` }, 'full_reference')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Entry mode</label>
                  <select
                    v-models="filters.entry_mode"
                    class="form-select"
                    @change="saveFilter({ customLabel: true, name: `Active: ${$event.target.value}`, value: $event.target.value }, 'entry_mode')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All
                    </option>
                    <option
                      value="Manually"
                    >
                      Manually
                    </option>
                    <option
                      value="Manually Copy-Paste"
                    >
                      Manually Copy-Paste
                    </option>
                    <option
                      value="Wos"
                    >
                      Wos
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Start year</label>
                  <date-picker
                    v-model="filters['start_year']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `Start year: ${$event}`, value: `${$event}` }, 'start_year')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >End year</label>
                  <date-picker
                    v-model="filters['end_year']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `End year: ${$event}`, value: `${$event}` }, 'end_year')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Indexed in WOS</label>
                  <select
                    v-model="filters.indexed_wos"
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Indexed in WOS: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'indexed_wos')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All
                    </option>
                    <option
                      value="true"
                    >
                      Yes
                    </option>
                    <option
                      value="false"
                    >
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Checked by ICREA</label>
                  <select
                    v-model="filters.checked_by_icrea"
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Checked by ICREA: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'checked_by_icrea')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All
                    </option>
                    <option
                      value="true"
                    >
                      Yes
                    </option>
                    <option
                      value="false"
                    >
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Signed as ICREA</label>
                  <select
                    v-model="filters.signed_icrea"
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Signed as ICREA: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'signed_icrea')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All
                    </option>
                    <option
                      value="true"
                    >
                      Yes
                    </option>
                    <option
                      value="false"
                    >
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Selected for Memoir</label>
                  <select
                    v-model="filters.show_memoir"
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Selected to Memoir: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'show_memoir')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All
                    </option>
                    <option
                      value="true"
                    >
                      Yes
                    </option>
                    <option
                      value="false"
                    >
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Verified signature</label>
                  <select
                    v-model="filters.verified_icrea"
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Verified signature: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'verified_icrea')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All
                    </option>
                    <option
                      value="true"
                    >
                      Yes
                    </option>
                    <option
                      value="false"
                    >
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Verified WOS</label>
                  <select
                    v-model="filters.verified_wos"
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Veriefied WOS: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'verified_wos')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All
                    </option>
                    <option
                      value="true"
                    >
                      Yes
                    </option>
                    <option
                      value="false"
                    >
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Status</label>
                  <v-select
                    v-model="filters.publication_status"
                    label="name"
                    :options="status"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'publicationStatus/filter')"
                    @input="saveFilter({ customLabel: true, name: `Status: ${$event ? $event.name : ''}`, value: $event }, 'publication_status')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Prior to the ICREA appointment</label>
                  <select
                    v-model="filters.icrea_appointment"
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Prior to the ICREA appointment: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'icrea_appointment')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All
                    </option>
                    <option
                      value="true"
                    >
                      Yes
                    </option>
                    <option
                      value="false"
                    >
                      No
                    </option>
                  </select>
                </div>
              </template>
              <template #info-sheet-item>
                <div class="offcanvas-body offcanvas-body--view">

                  <h4 class="mb-2">
                    <strong class="text-success">{{ selectedItems.length }}</strong> selected publications
                  </h4>

                  <ul
                    id="basic-list-group"
                    class="list-group"
                  >
                    <li class="list-group-item draggable">
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              id="tableAction1"
                              v-model="actions.icreaChecked"
                              type="checkbox"
                              class="form-check-input"
                            >
                            <label
                              class="form-check-label"
                              for="tableAction1"
                            >Checked by Icrea = yes</label>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item draggable">
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              id="tableAction1"
                              v-model="actions.verifiedSignature"
                              type="checkbox"
                              class="form-check-input"
                            >
                            <label
                              class="form-check-label"
                              for="tableAction1"
                            >Verified signature = yes</label>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item draggable">
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              id="tableAction2"
                              v-model="actions.deleteItem"
                              type="checkbox"
                              class="form-check-input"
                            >
                            <label
                              class="form-check-label"
                              for="tableAction2"
                            >Delete item</label>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item draggable">
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              id="tableAction3"
                              v-model="actions.wosIndexed"
                              type="checkbox"
                              class="form-check-input"
                            >
                            <label
                              class="form-check-label"
                              for="tableAction3"
                            >Indexed in wos</label>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item draggable">
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              id="tableAction4"
                              v-model="actions.icreaSigned"
                              type="checkbox"
                              class="form-check-input"
                            >
                            <label
                              class="form-check-label"
                              for="tableAction4"
                            >Signed as icrea</label>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item draggable">
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              id="tableAction5"
                              v-model="actions.icreaVerified"
                              type="checkbox"
                              class="form-check-input"
                            >
                            <label
                              class="form-check-label"
                              for="tableAction5"
                            >Verified and prior ICREA appointment</label>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li
                      v-if="loggedUser.roles.includes('super-admin') || loggedUser.roles.includes('publication-admin')"
                      class="list-group-item draggable"
                    >
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              id="tableAction6"
                              v-model="actions.sendEmail"
                              type="checkbox"
                              class="form-check-input"
                            >
                            <label
                              class="form-check-label"
                              for="tableAction6"
                            >Send email to authors</label>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="offcanvas-footer mt-auto">
                  <a
                    href="#"
                    title="apply actions"
                    class="btn btn-primary mb-1 d-grid w-100"
                    @click="applyActions"
                  >Apply actions</a>
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import CompleteTable from '../../partials/components/CompleteTable.vue'
import PublicationStats from './components/PublicationStats.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    vSelect,
    DatePicker,
    PublicationStats,
  },
  data() {
    return {
      selectedItems: [],
      actions: {
        icreaChecked: false,
        deleteItem: false,
        wosIndexed: false,
        icreaSigned: false,
        icreaVerified: false,
        sendEmail: false,
        verifiedSignature: false,
      },
      sortedField: 'Order',
      defaultFields: [
        {
          name: 'Researcher',
          checked: true,
          order: 1,
        },
        {
          name: 'Journal',
          checked: false,
          order: 1,
        },
        {
          name: 'Email',
          checked: false,
          order: 2,
        },
        {
          name: 'Title',
          checked: false,
          order: 3,
        },
        {
          name: 'Year',
          checked: true,
          order: 4,
        },
        {
          name: 'Type of publication',
          checked: true,
          order: 5,
        },
        {
          name: 'Reference publication',
          checked: true,
          order: 6,
        },
        {
          name: 'Status',
          checked: true,
          order: 7,
        },
        {
          name: 'Checked by ICREA',
          checked: true,
          order: 8,
        },
        {
          name: 'Signed with ICREA\'s affiliation',
          checked: true,
          order: 9,
        },
        {
          name: 'Verified signature',
          checked: true,
          order: 10,
          only_admin: true,
        },
        {
          name: 'Verified WOS',
          checked: true,
          order: 11,
          only_admin: true,
        },
        {
          name: 'Prior',
          checked: false,
          order: 12,
        },
        {
          name: 'Indexed in Web of Science',
          checked: false,
          order: 13,
        },
        {
          name: 'Wos comments',
          checked: false,
          order: 14,
        },
        {
          name: 'Selected to appear in Memoir',
          checked: true,
          order: 15,
        },
        {
          name: 'Order in Memoir',
          checked: true,
          order: 16,
        },
        {
          name: 'Selected to appear in Abridged CV',
          checked: true,
          order: 17,
        },
        {
          name: 'Signed comments',
          checked: false,
          order: 18,
        },
        {
          name: 'Entry mode',
          checked: false,
          order: 19,
        },
        {
          name: 'Link',
          checked: false,
          order: 20,
        },
        {
          name: 'File',
          checked: false,
          order: 21,
        },
      ],
      sortMapping: {
        Researcher: 'user.name',
        Journal: 'publication_name',
        'Order in Memoir': 'order_memoir',
        Email: 'user.email',
        Title: 'title',
        Year: 'year',
        'Type of publication': 'publication_type.name',
        'Reference publication': 'full_reference',
        Status: 'publication_status.name',
        Prior: 'icrea_appointment',
        'Indexed in Web of Science': 'indexed_wos',
        'Wos comments': 'comments_wos',
        'Selected to appear in Memoir': 'show_memoir',
        'Selected to appear in Abridged CV': 'show_abridged_cv',
        'Checked by ICREA': 'checked_by_icrea_table',
        'Signed with ICREA\'s affiliation': 'signed_icrea',
        'Verified signature': 'verified_icrea',
        'Verified WOS': 'verified_wos',
        'Signed comments': 'comments_icrea',
        'Entry mode': 'entry_mode',
        Link: 'link_publication',
        File: 'files_count',
      },
      initialFilters: {
        // Prefiltra default por el año en curso pero quiere ue me prefiltre por el 2023 ahora
        // start_year: new Date().getFullYear(),
        // end_year: new Date().getFullYear(),
        start_year: 2024,
        end_year: 2024,
      },
      hide: true,
    }
  },
  computed: {
    ...mapGetters({
      publications: 'publications/items',
      publicationsTotal: 'publications/itemsTotal',
      fields: 'modals/tableSettingsFields',
      types: 'publicationTypes/items',
      status: 'publicationStatus/items',
      users: 'users/fakeUsers',
      loggedUser: 'auth/admin',
      filters: 'filters/filters',
    }),
  },
  async mounted() {
    if (!this.filters.start_year) {
      this.saveFilter({ customLabel: true, name: `Start year: ${this.initialFilters.start_year}`, value: `${this.initialFilters.start_year}` }, 'start_year')
    }

    if (!this.filters.end_year) {
      this.saveFilter({ customLabel: true, name: `Start year: ${this.initialFilters.end_year}`, value: `${this.initialFilters.end_year}` }, 'start_year')
    }

    if (this.$route.query.user_id) {
      await this.saveFilter({ id: this.$route.query.user_id }, 'researcher')
    }
    await this.$store.dispatch('publications/filter', this.$store.getters['filters/filters'])
    await this.$store.dispatch('modals/fetchUserFields', 'publications')
    this.$store.dispatch('publicationTypes/filter', {})
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'publications',
      })
    }
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    async onSearch(search, store) {
      const s = search
      s.active = true
      if (s.name !== '') {
        await this.$store.dispatch(store, s)
      }
    },
    applyActions() {
      Vue.swal({
        title: 'Perform the following actions on the selected publications?',
        html: this.getActionsString(),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes, apply!',
      }).then(result => {
        if (result.isConfirmed && this.selectedItems.length > 0) {
          const itemIds = this.selectedItems.map(el => el.id)
          this.$store.dispatch('publications/applyActions', { actions: this.actions, itemIds }).then(async () => {
            this.$toastr.success('', 'Actions applied successfully!')
            await this.$store.dispatch('publications/filter', this.filtersTotal)
            this.selectedItems = []
            this.$store.dispatch('modals/toggleInfoItemSheet', false)
          })
        } else if (this.selectedItems.length === 0) {
          this.$toastr.error('', 'No items selected!')
        }
      })
    },
    saveSelectedItems(items) {
      this.selectedItems = items
    },
    getActionsString() {
      let str = ''

      if (this.actions.icreaChecked) {
        str += '<p class="text-success">Check by Icrea</p>'
      }

      if (this.actions.icreaSigned) {
        str += '<p class="text-success">Signed by Icrea</p>'
      }

      if (this.actions.icreaVerified) {
        str += '<p class="text-success">Verify and prior Icrea appointment</p>'
      }

      if (this.actions.deleteItem) {
        str += '<p class="text-warning">Delete the selected items</p>'
      }

      if (this.actions.wosIndexed) {
        str += '<p class="text-success">Index in wos</p>'
      }

      if (this.actions.sendEmail) {
        str += '<p class="text-success">Send email to the authors</p>'
      }

      if (this.actions.verifiedSignature) {
        str += '<p class="text-success">Verified signature</p>'
      }

      return str
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'publications', url: `${Vue.prototype.$groupUrl}/publications/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
