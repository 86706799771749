<!-- eslint-disable vue/html-self-closing -->
<template>
  <div>
    <!-- BEGIN: Content-->
    <div class="app-content content ms-0">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div
        v-if="loading"
      ></div>
      <div
        v-else
        class="content-wrapper container-xxl p-0"
      >
        <div class="content-header row">
          <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
              <div class="col-12">
                <h2 class="content-header-title float-start mb-0">
                  {{ section.title }}
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div class="content-body">
          <div class="row">
            <div class="col-sm-12">
              <p v-html="section.description" />
            </div>
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
              <div class="row">
                <div
                  class="col-sm-4"
                  :class="percentage >= 100 || Number.isNaN(percentage) || percentage == 'NaN' ? 'text-success' : 'text-danger'"
                >
                  <p>Required fields filled <strong>{{ !Number.isNaN(percentage) && percentage != 'NaN' ? percentage : '100' }}%</strong></p>
                </div>
              </div>
            </div>
          </div>

          <section
            id="senior__call__form"
            class="senior__call__form"
          >
            <div class="row">
              <div class="col">
                <form action="">
                  <Group
                    v-for="(group) in groups"
                    :key="group.id"
                    :group="group"
                    :section-id="section.id"
                    :convo-id="$route.params.convo_id"
                    :save-answer="convo.open_applicants"
                    :user-id="user.id"
                  />
                </form>
              </div>
            </div>

            <input
              id="trigger-blur"
              style="opacity:0;width:0px;height:0px;cursor:default;"
              type="text"
            >

            <div class="row">
              <div class="col">
                <div class="senior__call__form__actions">
                  <!-- <a href="#" class="btn btn-warning">Save and close</a> -->
                  <button
                    class="btn btn-success"
                    @click="returnToConvo()"
                  >Continue</button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <!-- END: Content-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Form from 'vform'
import Vue from 'vue'
import Group from './partials/Group.vue'

export default {
  components: {
    Group,
  },
  data() {
    return {
      loading: false,
      requiredFields: 0,
      filledRequiredFields: 0,
      groups: {},
    }
  },
  computed: {
    ...mapGetters({
      section: 'convoSection/section',
      percentage: 'convoSection/percentage',
      convo: 'convo/convo',
      user: 'auth/user',
    }),
  },
  mounted() {
    Form.axios = Vue.prototype.$http
    this.fetch()
  },
  methods: {
    async fetch() {
      this.loading = true
      if (!this.convo.id) {
        if (this.$route.name.includes('senior-call')) {
          await this.$store.dispatch('convo/fetch')
        } else if (this.$route.name.includes('academy-call')) {
          await this.$store.dispatch('convo/academyFetch')
        }
      }
      await this.$store.dispatch('convoSection/fetch', this.$route.params.id)

      if (!this.section) {
        return this.$router.push({ name: 'error-404' })
      }
      document.title = `ICREA - ${this.convo.title} - ${this.section.title}`
      this.loading = false

      await this.$store.dispatch('convoSection/fetchGroups', { sectionId: this.section.id, convoId: this.convo.id })
      this.groups = this.$store.getters['convoSection/groups']

      return true
    },
    returnToConvo() {
      // If another input is focused it triggers blur, so...
      document.getElementById('trigger-blur').focus()

      setTimeout(() => {
        if (this.$route.name.includes('senior-call')) {
          this.$router.push({ name: 'front.senior-call' })
        } else if (this.$route.name.includes('academy-call')) {
          this.$router.push({ name: 'front.academy-call' })
        }
      }, 50)
    },
  },
}
</script>
